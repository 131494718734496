import { createStyles, makeStyles, Theme } from '@material-ui/core';

const PagesItemStyle = makeStyles((theme: Theme) =>
  createStyles({
    pagesItem: {
      display: 'flex',
      flexFlow: 'column',
      boxShadow: '0px 0.125rem 1.25rem hsla(var(--valor-primario-azul-oscuro), 0.14)',
      borderRadius: '1.25rem',
    },
    flecha: {
      '--icon-primary-dark': 'var(--primario-morado)',
      marginRight: '0.625rem',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      padding: '1.1875rem 1.75rem 1.1875rem 2.375rem',
      borderBottom: '1px solid var(--secundario-gris-azulado)',
      width: '95%',
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.down('sm')]: {
        padding: '1.125rem 5% 1.375rem 5%',
        width: '90%',
      },
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      color: 'var(--secundario-azul-marino)',
      justifyContent: 'center',
      flexGrow: 1,
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '26px',
    },
    body: {
      padding: '2rem 2.375rem 2.75rem 2.375rem',
      [theme.breakpoints.down('sm')]: {
        padding: '1.5rem 5%',
        width: '100%',
      },
    },
    ocultar: {
      display: 'none !important',
    },
    botonVolver: {
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      position: 'absolute',
      fontSize: '14px',
      fontWeight: 600,
      marginLeft: '-38px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0',
      },
    },
    botonVolverTexto: {
      [theme.breakpoints.down('sm')]: {
        display: 'none !important',
      },
    },
    flechaAtras: {
      '--icon-primary-dark': 'hsla(var(--valor-primario-morado), 1)',
    },
  })
);

export default PagesItemStyle;
