export enum TipoColoresEnum {
  VERDE_CLARO = 'verdeClaro',
  VERDE_OSCURO = 'verdeOscuro',
  ROJO = 'rojo',
  AMARILLO = 'amarillo',
  GRIS_L93 = 'grisL93',
  GRIS_L83 = 'grisL83',
  GRIS_L63 = 'grisL63',
  GRIS_L43 = 'grisL43',
}
