import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ModalGlobalStyles from 'utils/styles/ModalGlobalStyles';

const BotonCerrar = ({ cerrarModal }: { cerrarModal: () => void }) => {
  /**
   * Componente styles de modal global
   */
  const stylesGlobal = ModalGlobalStyles();

  /**
   * Componente de traduccion i18n
   */
  const { t } = useTranslation();

  return (
    <div className={stylesGlobal.contenedorBotones}>
      <Button
        className={stylesGlobal.boton}
        type="button"
        variant="contained"
        color="primary"
        onClick={cerrarModal}
        data-testid="cerrarModalBoton"
      >
        {t('desconexion:paginas.modalSitioNoDisponible.botonCerrar')}
      </Button>
    </div>
  );
};

export default BotonCerrar;
