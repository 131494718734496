import { useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { tiempoRecargaV2 } from 'utils/constants';

const CaptchaV2 = ({ setTokenCaptchaV2 }: { setTokenCaptchaV2: any }) => {
  /**
   * Referencia de captcha
   */
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  /**
   *  Clave del captcha desde el entorno de variables
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const googleCaptchaKeyV2 = process.env.REACT_APP_CAPTCHA_KEY_V2 || '';

  /**
   * Función para reiniciar el captcha
   */
  const reiniciarCaptcha = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  /**
   * UseEffect para reiniciar el captcha automáticamente cada 2 minutos
   */
  useEffect(() => {
    const intervalo = setInterval(() => {
      reiniciarCaptcha();
    }, tiempoRecargaV2);

    return () => clearInterval(intervalo);
  }, []);

  /**
   * Función controladora de la expiración del token del captcha
   */
  const handleExpired = () => {
    // Se reinicia el captcha manualmente si expira
    reiniciarCaptcha();
  };

  return (
    <ReCAPTCHA
      ref={recaptchaRef}
      data-testid="recaptcha"
      sitekey={googleCaptchaKeyV2}
      onExpired={handleExpired}
      onChange={value => {
        setTokenCaptchaV2(value);
      }}
    />
  );
};

export default CaptchaV2;
