import { Hidden } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  CLIENTEHOGAR,
  CLIENTEMPRESA,
  CONTACTO,
  GRUPOSAESA,
  HERRAMIENTAS,
  INDICADORES,
  INVERSIONISTA,
  QUEINESOMOS,
  SOMOSVECINOS,
} from 'root-page';
import { RootState } from 'store';
import useStyles from './SidemenuStyles';

// eslint-disable-next-line react/no-unused-prop-types
const Sidemenu = ({ className }: { className?: string; path: string }) => {
  const styles = useStyles();
  /**
   * translate
   */
  const { t } = useTranslation();

  const menuAbierto = useSelector((state: RootState) => state.desconexion.menuAbierto);

  /**
   * Arreglo que contiene menu de navegacion de menu tope del header
   */
  const menuMobile = [
    {
      id: 0,
      option: t('desconexion:paginas.mapaCortes.home.menu.grupoSaesa'),
      url: GRUPOSAESA,
    },
    {
      id: 1,
      option: t('desconexion:paginas.mapaCortes.home.menu.quienesSomos'),
      url: QUEINESOMOS,
    },
    {
      id: 2,
      option: t('desconexion:paginas.mapaCortes.home.menu.inversionistas'),
      url: INVERSIONISTA,
    },
    {
      id: 3,
      option: t('desconexion:paginas.mapaCortes.home.menu.indicadores'),
      url: INDICADORES,
    },
    {
      id: 4,
      option: t('desconexion:paginas.mapaCortes.home.menu.herramientas'),
      url: HERRAMIENTAS,
    },
    {
      id: 5,
      option: t('desconexion:paginas.mapaCortes.home.menu.contacto'),
      url: CONTACTO,
    },
    {
      id: 6,
      option: t('desconexion:paginas.mapaCortes.home.menu.somosVecinos'),
      url: SOMOSVECINOS,
    },
    {
      id: 7,
      option: t('desconexion:paginas.mapaCortes.home.menu.clienteHogar'),
      url: CLIENTEHOGAR,
    },
    {
      id: 8,
      option: t('desconexion:paginas.mapaCortes.home.menu.clienteEmpresa'),
      url: CLIENTEMPRESA,
    },
  ];

  return (
    <aside
      className={clsx(className, styles.sidemenu, {
        [styles.sidemenuOpen]: menuAbierto,
      })}
    >
      <Hidden only={['md', 'lg', 'xl']}>
        <div className={styles.divisorCnt}>
          <div className={styles.divisor} />
        </div>
        <ul className={styles.itemCnt}>
          {menuMobile.map(item => (
            <li className={styles.sidemenuItemCnt}>
              <a className={styles.sidemenuItem} href={item.url} target="_blank" rel="noopener noreferrer">
                {' '}
                {item.option}
              </a>
            </li>
          ))}
        </ul>
      </Hidden>
    </aside>
  );
};

Sidemenu.defaultProps = {
  className: '',
};

export default Sidemenu;
