import { endOfDay, format } from 'date-fns';

const LARGO_FORMATO_FECHA = 10;
const LARGO_FORMATO_FECHA_ISO = 19;
const FORMATO_FECHA_DIA_MES_ANIO = 'dd/MM/yyyy';
const LOCALE_ES_CL = 'es-CL';
/**
 *
 * @param time Tiempo expresado en string
 * @returns Reemplaza guiones - por slash /
 */
const withSlash = (time: string) => time && time.replace(/-/g, '/');
/**
 *
 * @param time Tiempo expresado en string
 * @returns Tiempo en String formateado de YYYY-MM-DD a DD/MM/YYYY
 */
const dateToString = (time: string) => {
  const timeFormatter = time
    ? `${new Date(withSlash(time)).toLocaleDateString(LOCALE_ES_CL, {
        hour12: false,
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })}`
    : '';
  return timeFormatter.replace(/-/g, '/');
};

/**
 * Metodo que se encarga de validar si un dato existe.
 * @param dato valor del dato a validar.
 * @returns true si es valido, false si es invalido.
 */
export const esDatoValido = (dato: any) => dato && dato !== undefined && dato !== null;

/**
 * Recibe una fecha de tipo string y la transforma en el formato dd/MM/yyyy
 * @param dato fecha enviada
 * @returns fecha formateada
 */
export const formatoFecha = (dato: string) => {
  if (!esDatoValido(dato)) {
    return '';
  }
  if (dato.length === LARGO_FORMATO_FECHA) {
    return dato && dateToString(dato);
  }
  if (dato.length === LARGO_FORMATO_FECHA_ISO) {
    return dato && format(endOfDay(new Date(dato)), FORMATO_FECHA_DIA_MES_ANIO);
  }
  return '';
};

/**
 * Recibe una fecha de tipo string y obtiene la hora
 * @param dato fecha enviada
 * @returns fecha formateada
 */
export const obtenerHora = (dato: any) => {
  if (dato !== null) return `${dato.split('T')[1].substring(0, 5)} hrs.`;
  return '';
};

/**
 * Recibe una fecha de tipo string y la transforma en el formato dd/MM/yyyy - HH:MM
 * @param dato fecha enviada
 * @returns fecha formateada
 */
export const formatoFechaConHora = (dato: string) => dato && `${formatoFecha(dato)} - ${obtenerHora(dato)}`;

/**
 * Obtiene sin determinar si no viene fecha
 * @param dato fecha enviada
 * @param t componente traduccion
 * @returns retorna la fecha o el sin determinar
 */
export const fechaSinDeterminar = (dato: string, t: any) => dato || t('desconexion:paginas.tracking.corte.avisoPago.textos.sinDeterminar');

/**
 * Función que permite realizar llevar al usuario a la parte inicial del modal
 */
export const scrollInicialModal = () => {
  // Obtener la ventana modal (ajusta el selector según estructura HTML)
  const ventanaModal = document.getElementById('modal-poligono');

  // Verificar si la ventana modal existe
  if (ventanaModal) {
    // Desplazar la ventana modal hasta el final
    ventanaModal.scrollTop = 0;
  }
};
