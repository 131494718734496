import { EventoAnalytics } from 'models/eventoAnalyticsModel.model';
import TagManager from 'react-gtm-module';

declare global {
  interface Window {
    dataLayer: any;
  }
}

/**
 * Clase encargada de realizar marcaje en GTM
 */
class AnalyticsService {
  /**
   * Funcion encargada de inicializar tag manager
   */
  static inicializarGTM() {
    const tagManagerArgs = {
      gtmId: String(process.env.REACT_APP_GTM),
    };
    TagManager.initialize(tagManagerArgs);
  }

  /**
   * Función encargada de realizar envío de un evento a tag manager.
   *
   * @param ev evento a tagear
   * @returns envio tag manager
   */
  static enviarTag(ev: EventoAnalytics) {
    return window.dataLayer?.push({
      event: ev.evento,
      attributes: {
        eventLabel: ev.nombre,
        eventCategory: ev.categoria,
        eventId: process.env.REACT_APP_ANALYTICS,
      },
    });
  }
}
export default AnalyticsService;
