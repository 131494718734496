import { createStyles, makeStyles, Theme } from '@material-ui/core';

const InfoClienteStyles = makeStyles((theme: Theme) =>
  createStyles({
    servicio: {
      display: 'flex',
      background: 'hsla(var(--valor-primario-morado), 0.05)',
      borderRadius: '0.625rem',
      padding: '1.125rem 1rem',
      [theme.breakpoints.down('sm')]: {
        minWidth: 'initial',
        padding: '1.25rem .625rem',
      },
    },
    headerServicio: {
      display: 'flex',
      marginLeft: '1rem',
      marginRight: '15rem',
    },
    bodyServicio: {
      margin: '1rem 0 0 1rem',
    },
    contenedorEstado: {
      display: 'flex',
      flexDirection: 'column',
      gap: '6px',
      maxWidth: '70%',
    },
    numeroCliente: {
      display: 'flex',
    },
    calle: {
      display: 'flex',
    },
    comuna: {
      display: 'flex',
    },
    localidad: {
      display: 'flex',
    },
    estado: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        width: '200%',
      },
    },
    estadoActivo: {
      color: '#0B9D65',
      marginLeft: '0.25rem',
      fontWeight: 600,
    },
    estadoInactivo: {
      color: 'var(--rojo-error)',
      marginLeft: '0.25rem',
      fontWeight: 600,
    },
    estadoTransicion: {
      color: '#E08A00',
      marginLeft: '0.25rem',
      fontWeight: 600,
    },
    tituloCampo: {
      marginLeft: '0.25rem',
    },
    campo: {
      marginLeft: '0.25rem',
    },
    botonVolver: {
      padding: '0',
      height: '16px',
      textDecoration: 'none',
    },
    editar: {
      textAlign: 'right',
      color: 'var(--primario-morado)',
      fontSize: '14px',
      fontWeight: 600,
    },
  })
);

export default InfoClienteStyles;
