import { ErrorInterceptor } from 'config';
import { ROOT } from 'navigation';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { MAPACORTES, RootPage } from 'root-page';

const RootRouterConfig = () => {
  const { t } = useTranslation();

  ErrorInterceptor(t);

  return (
    <Switch>
      <Redirect path={ROOT} exact to={MAPACORTES} />
      <Route path={ROOT}>
        <RootPage path={ROOT} />
      </Route>
    </Switch>
  );
};

export default RootRouterConfig;
