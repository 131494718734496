import { Typography, Box } from '@material-ui/core';
import { alerta } from 'assets/images';
import { useTranslation } from 'react-i18next';
import useStyles from './DisclaimerStyles';

const Disclaimer = ({ horaActualizacion }: { horaActualizacion: string }) => {
  /**
   * Variable que maneja el uso de estilos.
   */
  const styles = useStyles();

  /**
   * Uso del traductor
   */
  const { t } = useTranslation();

  return (
    <Box className={styles.card}>
      <img src={alerta} alt="alerta" className={styles.icono} />
      <Box className={styles.frame}>
        <Typography className={styles.text}>{t('desconexion:paginas.mapaCortes.modalTracking.disclaimer.imprevistos.titulo')}</Typography>
        {horaActualizacion != null && horaActualizacion !== '' && (
          <Typography className={styles.subtext}>
            {t('desconexion:paginas.mapaCortes.modalTracking.disclaimer.imprevistos.subTitulo')}
            {`${horaActualizacion} ${t('desconexion:paginas.mapaCortes.modalTracking.disclaimer.imprevistos.fechaHoras')}`}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Disclaimer;
