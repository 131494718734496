import { TipoEstadoActualSecundarioTrackingCorteEnum } from 'config';
import { TIPOCORTE } from 'config/tiposCorte';

/**
 * Función que determina si un valor es no valido
 * @param data parametro que sera evaluado
 * @returns un valor boleano con el estado de no valido
 */
export const datoNoValido = (data: string | undefined | null): boolean => data === null || data === undefined || data.trim() === '';

/**
 * Función que determina si un corte es imprevisto
 * @param corte tipo de corte
 * @returns un boleano con el estado de si es corte imprevisto
 */
export const esCorteImprevisto = (corte: any): boolean => corte === TIPOCORTE.CORTE_IMPREVISTO;

/**
 * Función que determina si un corte no es imprevisto
 * @param corte tipo de corte
 * @returns un boleano con el estado de si no es corte imprevisto
 */
export const noEsCorteImprevisto = (corte: any): boolean => corte !== TIPOCORTE.CORTE_IMPREVISTO;

/**
 *  Función que determina si un corte es imprevisto y viene sin arribo de brigada
 * @param corte tipo de corte
 * @param arriboBrigada fecha arribo briigada
 * @param estadoActualSecundario estado secundario
 * @returns un boleano con el estado de si es corte imprevisto y sin arribo de brigada
 */
export const esCorteImprevistoSinArriboBrigada = (corte: any, arriboBrigada: any, estadoActualSecundario: any): boolean =>
  (esCorteImprevisto(corte) && (arriboBrigada === null || arriboBrigada === undefined || arriboBrigada.trim() === '')) ||
  (esCorteImprevisto(corte) && estadoActualSecundario === TipoEstadoActualSecundarioTrackingCorteEnum.CONFIRMADO);

/**
 *  Función que determina si un corte es imprevisto y viene con arribo de brigada
 * @param corte tipo de corte
 * @param arriboBrigada fecha arribo briigada
 * @param estadoActualSecundario estado secundario
 * @returns un boleano con el estado de si es corte imprevisto y con arribo de brigada
 */
export const esCorteImprevistoConArriboBrigada = (corte: any, arriboBrigada: any, estadoActualSecundario: any): boolean => {
  const tieneArriboBrigadaValido = !datoNoValido(arriboBrigada);
  const esCorteImprevistoCondicion = esCorteImprevisto(corte);

  return (
    (esCorteImprevistoCondicion &&
      tieneArriboBrigadaValido &&
      estadoActualSecundario !== TipoEstadoActualSecundarioTrackingCorteEnum.CONFIRMADO) ||
    (esCorteImprevistoCondicion &&
      tieneArriboBrigadaValido &&
      estadoActualSecundario === TipoEstadoActualSecundarioTrackingCorteEnum.NORMALIZADO)
  );
};

/**
 * Función para determinar si el estado es brigada en terreno
 * @param estadoActualSecundario tipo de estado del corte
 * @returns boleano que retorna si la brigada esta en terreno
 */
export const esBrigadaEnTerreno = (estadoActualSecundario: any): boolean =>
  estadoActualSecundario === TipoEstadoActualSecundarioTrackingCorteEnum.BRIGADA_EN_TERRENO ||
  estadoActualSecundario === TipoEstadoActualSecundarioTrackingCorteEnum.NORMALIZADO;

/**
 * Función para determinar si el estado es brigada asignada
 * @param estadoActualSecundario tipo de estado del corte
 * @returns boleano que retorna si la brigada esta asignada
 */
export const esBrigadaAsignada = (estadoActualSecundario: any): boolean =>
  estadoActualSecundario === TipoEstadoActualSecundarioTrackingCorteEnum.BRIGADA_ASIGNADA &&
  estadoActualSecundario !== TipoEstadoActualSecundarioTrackingCorteEnum.NORMALIZADO;
