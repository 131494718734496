import { createStyles, makeStyles, Theme } from '@material-ui/core';

const RootStyles = makeStyles((theme: Theme) =>
  createStyles({
    lineaTope: {
      width: '100%',
      height: '24px',
      position: 'relative',
      background: 'var(--primario-azul-oscuro)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    menuRoot: {
      display: 'flex',
      alignItems: 'center',
      margin: '0',
    },
    item: {
      listStyleType: 'none',
      marginRight: '2.375rem',
      cursor: 'pointer',
      fontSize: '0.875rem',
    },
    link: {
      color: 'white',
      textDecoration: 'none',
    },
    logo: {
      width: '160px',
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      marginRight: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '110px',
        height: '36px',
      },
    },
    desconexion: {
      minHeight: '100vh',
      maxHeight: '100vh',
    },
    header: {
      display: 'flex',
      padding: '1.25rem 3.125rem',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        position: 'relative',

        padding: '1.25rem 1.25rem .75rem .75rem',
        alignItems: 'center',
        boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px;',
        zIndex: 9,
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1,
      paddingBottom: '0',
      background: 'linear-gradient(180deg, var(--secundario-gris-azulado) 12%, var(--blanco) 108%)',
    },
  })
);

export default RootStyles;
