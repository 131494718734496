import { createStyles, makeStyles } from '@material-ui/core';
import { theme } from '@saesa/ui-kit-front';

const DisclaimerTerVencidoStyles = makeStyles(() =>
  createStyles({
    contenedor: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: '16px 20px',
      gap: '16px',
      background: '#FFF3E0',
      border: '1px solid #E08A00',
      borderRadius: '10px',
      marginBottom: '16px',
      [theme.breakpoints.down('sm')]: {},
    },
    contenedorInterno: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      padding: '0px',
      gap: '12px',
    },
    contenedorParrafos: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0px',
      gap: '4px',
    },
    parrafoGlobal: {
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '18px',
      color: 'var(--gris-l3)',
    },
    parrafoTitulo: {
      fontWeight: 600,
    },
    parrafoSubTitulo: {
      fontWeight: 400,
    },
  })
);

export default DisclaimerTerVencidoStyles;
