/* eslint-disable func-names */
/* eslint-disable react/no-this-in-sfc */
import React, { ReactNode } from 'react';
import { validate as validateRut } from 'rut.js';
import * as Yup from 'yup';

const FormValidation = ({ children }: { children: ReactNode }) => {
  Yup.addMethod(Yup.string, 'rut', function (message: string) {
    return this.test('rut-test', message, rut => (rut ? validateRut(rut) : false));
  });

  Yup.addMethod(Yup.string, 'soloNumero', function (message?: string) {
    return this.matches(new RegExp(/^\d*$/), message);
  });

  Yup.addMethod(Yup.string, 'noNumero', function (message?: string) {
    return this.matches(new RegExp(/^[a-zA-Z\s]*$/), message);
  });

  Yup.addMethod(Yup.string, 'equalTo', function (ref, msg) {
    return this.test({
      name: 'equalTo',
      exclusive: false,
      // eslint-disable-next-line no-template-curly-in-string
      message: msg || '${path} must be the same as ${reference}',
      params: {
        reference: ref.path,
      },
      test(value: any) {
        return value === this.resolve(ref);
      },
    });
  });
  return <>{children}</>;
};

export default FormValidation;
