import { createStyles, makeStyles, Theme } from '@material-ui/core';

const VistasCorteStyles = makeStyles((theme: Theme) =>
  createStyles({
    numeroServicio: {
      width: '290px',
      display: 'flex',
      margin: '0 auto',
      marginBottom: '1.5rem',
      [theme.breakpoints.down('sm')]: {
        margin: '',
      },
    },
    stepper: {
      justifyContent: 'center',
      marginBottom: '2rem',
    },
    stepperCorte: {
      justifyContent: 'center',
      marginBottom: '2rem',
      [theme.breakpoints.down('xs')]: {
        marginBottom: 0,
      },
    },
    botonIr: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    input: {
      maxWidth: '396px',
    },
    recaptcha: {
      width: '280px',
      margin: '0 auto',
      transform: 'translateX(-11px)',
      marginBottom: '2rem',
    },
    tooltip: {
      margin: '0',
      padding: '0',
      height: '30px',
      zIndex: 2,
    },
    tooltipIcon: {
      '--icon-primary-dark': 'var(--primario-morado)',
    },
    etapas: {
      margin: '2.75rem auto 0 auto',
      width: '600px',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        marginTop: '0',
      },
    },
    textos: {
      maxWidth: '490px',
      margin: '0 auto',
    },
    descripcion: {
      margin: '.5rem 0 2.125rem',
      textAlign: 'center',
    },
    imgTooltip: {
      margin: '0 auto',
    },
    textoInformativo: {
      marginBottom: '1rem',
      maxWidth: '800px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '1.5rem',
      },
    },
    avisoPago: {
      margin: '0 auto',
      maxWidth: '712px',
    },
    reportarCorteCnt: {
      width: '100%',
      textAlign: 'center',
      marginTop: '2rem',
    },
    btnReportar: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    textoCorte: {
      marginBottom: '1rem',
    },
    iconReportar: {
      marginLeft: '0.5rem',
      '--icon-primary-light': 'var(--primario-morado)',
    },
    infoCnt: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'Center',
      justifyContent: 'center',
      position: 'relative',
    },
    iconoManoArriba: {
      margin: '2.25rem auto 2.375rem auto',
      '--icon-primary-dark': 'hsla(var(--valor-primario-morado), 0.5)',
      '--icon-primary-light': 'hsla(var(--valor-primario-morado), 0.5)',
      '--icon-purple': 'var(--secundario-azul-marino)',
      [theme.breakpoints.down('sm')]: {
        margin: '1.875rem auto 1rem auto',
      },
    },
    line: {
      width: '100%',
      maxWidth: 'calc(100% - 345px)',
      position: 'absolute',
      left: '50%',
      bottom: '30%',
      height: '1px',
      background: 'var(--gris-l93)',
      transform: 'translateX(-50%)',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '90%',
        bottom: '36%',
      },
    },
    info: {
      textAlign: 'center',
      margin: '1rem 0 1rem',
      [theme.breakpoints.down('sm')]: {
        margin: '1rem 0 1rem',
      },
    },
    formCnt: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexFlow: 'column',
    },
  })
);

export default VistasCorteStyles;
