import { createStyles, makeStyles } from '@material-ui/core';
import { theme } from '@saesa/ui-kit-front';

const VentanaMobileStyles = makeStyles(() =>
  createStyles({
    cajaVentana: {
      height: 'auto',
      minHeight: '225px',
      width: '100vw',
      minWidth: '100vw',
      color: 'black',
      background: '#FFFFFF;',
      position: 'fixed',
      zIndex: 25,
      bottom: 0,
      margin: 'auto',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '20px',
      gap: '16px',
    },

    circuloGris: {
      display: 'flex',
      width: '13px',
      height: '13px',
      backgroundColor: '#CCCFDB',
      borderRadius: '50%',
      marginRight: '15px',
    },

    contenedorBotonMobile: {
      display: 'flex',
      justifyContent: 'flex-end',
    },

    cajaTituloMobile: {
      marginTop: '0rem',
      display: 'flex',
      alignItems: 'center',
    },
    textoTituloMobile: {
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '18px',
    },
    textoParrafoMobile: {
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontSize: '14px',
      paddingTop: '2px',
      lineHeight: '18px',
    },
    sizeSmallMobile: {
      padding: '1px',
    },
    lineaMobile: {
      border: '1px solid #EAEBF0',
      width: '100%',
      marginTop: '1.3rem',
    },
    boton: {
      display: 'inline-flex',
      color: 'var(--blanco)',
      border: '2px solid transparent',
      boxShadow: 'unset',
      backgroundColor: 'var(--primario-morado)',
      borderRadius: '2.5rem',
      cursor: 'pointer',
      position: 'relative',
      alignItems: 'center',
      verticalAlign: 'middle',
      justifyContent: 'center',
      textDecoration: 'none',
      height: '2.813rem',
      padding: '14px 32px 13px 32px',
      minWidth: '64px',
      marginBottom: '1.12rem',
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      '&:hover': {
        color: 'var(--blanco)',
        border: '2px solid var(--hover-primary)',
        backgroundColor: 'var(--hover-primary)',
      },
      [theme.breakpoints.down('sm')]: {
        width: '90vw',
      },
    },
    cajaBoton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    cajaMobileInformacion: {
      display: 'flex',
    },

    textoTituloInformacion: {
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '18px',
      marginRight: '5px',
    },

    textoParrafoInformacion: {
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
    },
    cajaContenedoraInformacion: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0px',
      gap: '8px',
      width: '335px',
      height: '44px',
    },
    textoBotonVerMasDetalles: {
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '18px',
      textAlign: 'center',
      color: '#F9F9FB',
    },
    contenedorTituloBoton: {
      width: '100%',
    },
  })
);

export default VentanaMobileStyles;
