import { createStyles, makeStyles, Theme } from '@material-ui/core';

const textoInformativoStyles = makeStyles((theme: Theme) =>
  createStyles({
    titulo: {
      position: 'relative',
      textAlign: 'center',
      marginBottom: '.5rem',
      fontSize: '1rem',
      [theme.breakpoints.down('sm')]: {},
    },
    textoBajada: {
      position: 'relative',
      textAlign: 'center',
      width: '100%',
      margin: '0 auto',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'start',
      },
    },
  })
);

export default textoInformativoStyles;
