import { createStyles, makeStyles } from '@material-ui/core';
import { theme } from '@saesa/ui-kit-front';

const ColorBallsStyles = makeStyles(() =>
  createStyles({
    contenedor: {
      display: 'flex',
      flexDirection: 'row',
      gap: '16px',
      marginBottom: '20px',
      marginLeft: '28px',
      width: 'calc(100% - 28px)',
    },

    contenedorInterno: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginTop: '5px',
    },
    contenedorIconoPrimero: {
      backgroundColor: 'var(--rojo-error)',
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      display: 'flex',
      alignContent: 'center',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },

    contenedorIconoSegundo: {
      backgroundColor: 'var(--gris-l63)',
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      display: 'flex',
      alignContent: 'center',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    contenedorIconoFinalBrigada: {
      backgroundColor: 'var(--gris-l83)',
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      display: 'flex',
      alignContent: 'center',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    contenedorIconoCirculoSinIcono: {
      backgroundColor: 'var(--rojo-error)',
      borderRadius: '50%',
      width: '20px',
      height: '20px',
      display: 'flex',
      alignContent: 'center',
      flexWrap: 'wrap',
      justifyContent: 'center',
      marginTop: '-1px',
      marginBottom: '-1px',
    },
    contenedorIconoCirculoSinIconoSinBrigadaTerreno: {
      backgroundColor: 'var(--gris-l63)',
      borderRadius: '50%',
      width: '20px',
      height: '20px',
      display: 'flex',
      alignContent: 'center',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    contenedorLinea: {
      width: '6px',
      height: '54px',
      position: 'relative',
      margin: '0px',
    },

    contenedorLineaUnionSinBrigadaTerreno: {
      width: '6px',
      height: '54px',
      position: 'relative',
      margin: '0px',
      backgroundColor: 'var(--gris-l93)',
    },
    parteSuperiorLinea: {
      width: '100%',
      height: '50%',
      backgroundColor: 'var(--rojo-l50)',
      position: 'absolute',
      top: 0,
      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },
    parteInferiorLinea: {
      width: '100%',
      height: '100%',
      backgroundColor: 'var(--gris-l93)',
    },
    contenedorLineaBrigada: {
      width: '6px',
      height: '46px',
      position: 'relative',
      margin: '0px',
    },
    contenedorSegundaLinea: {
      width: '6px',
      height: '36px',
      position: 'relative',
      margin: '0px',
    },
    contenedorSegundaLineaTerreno: {
      width: '6px',
      height: '30px',
      position: 'relative',
      margin: '0px',
    },
    contenedorTerceraLinea: {
      width: '6px',
      height: '50px',
      position: 'relative',
      margin: '0px',
    },
    contenedorTerceraLineaSinTerreno: {
      width: '6px',
      height: '67px',
      position: 'relative',
      margin: '0px',
    },
    parteInferiorLineaBrigada: {
      width: '100%',
      height: '100%',
      backgroundColor: 'var(--rojo-l50)',
    },

    parteInferiorSegundaLinea: {
      width: '100%',
      height: '100%',
      backgroundColor: 'var(--rojo-l50)',
    },

    lineaUnionPrimeraEtapa: {
      width: '5px',
      height: '54px',
      background: 'linear-gradient(to top, var(--gris-l93) 50%, var(--rojo-l50) 50%)',
      margin: '0px',
    },
    textoGlobal: {
      fontFamily: 'Neutrif Studio',
    },
    tituloConfirmado: {
      fontWeight: 600,
      fontSize: '0.911vw',
      lineHeight: '18px',
      color: 'var(--rojo-error)',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
    tituloReposicion: {
      fontWeight: 600,
      fontSize: '0.911vw',
      lineHeight: '18px',
      color: 'var(--gris-l43)',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
    tituloBrigadaSinTerreno: {
      fontWeight: 600,
      fontSize: '0.911vw',
      lineHeight: '18px',
      color: 'var(--gris-l43)',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
    tituloBrigada: {
      fontWeight: 600,
      fontSize: '0.911vw',
      lineHeight: '18px',
      color: 'var(--gris-l3)',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
    },
    complementoBrigadaAsignada: {
      marginTop: '42px',
      marginBottom: '27px',
    },
    complementoBrigadaAsignadaSinTerreno: {
      marginTop: '31px',
    },
    complementoBrigadaAsignadaSinTerrenoTexto: {
      marginBottom: '52px',
    },
    textoHoraReposicion: {
      fontWeight: 400,
      fontSize: '0.78125vw',
      lineHeight: '16px',
      color: 'var(--gris-l43)',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
    textoPrincipalMotivoCorte: {
      fontWeight: 600,
      fontSize: '0.78125vw',
      lineHeight: '0px',
      color: 'var(--gris-l3)',
      display: 'inline',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
    textoPrincipalBrigadaTerreno: {
      fontWeight: 400,
      fontSize: '0.78125vw',
      lineHeight: '0px',
      color: 'var(--gris-l3)',
      display: 'inline',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
    textoPrincipalBrigadaTerrenoLink: {
      cursor: 'pointer',
      fontWeight: 600,
      fontSize: '0.78125vw',
      lineHeight: '0px',
      color: 'var(--primario-morado)',
      display: 'inline',
      textDecoration: 'underline',
      userSelect: 'none',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
    textoMotivoCorte: {
      fontWeight: 400,
      fontSize: '0.78125vw',
      lineHeight: '0px',
      color: 'var(--gris-l3)',
      display: 'inline',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },

    contenedorTextosMotivoCorte: {
      display: 'block',
      lineHeight: '16px',
      [theme.breakpoints.down('sm')]: {},
    },
    contenedorTextos: {
      display: 'flex',
      flexDirection: 'column',
      gap: '48px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '80%',
      },
    },
    contenedorInternoBolaCorte: {
      display: 'flex',
    },
    contenedorPrimerosTexto: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0px',
      gap: '4px',
      width: '16.2760vw',
      height: '54px',
      flex: 'none',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    contenedorTextosBrigada: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0px',
      width: '16.2760vw',
      flex: 'none',
      [theme.breakpoints.down('sm')]: {
        width: '98%',
        height: '169px',
      },
    },
    contenedorSegundosTexto: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0px',
      gap: '4px',
      width: '251px',
      height: '54px',
      flex: 'none',
    },

    contenedorTextoReposicion: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '0px',
      gap: '4px',
      height: '54px',
      flex: 'none',
    },
    complementoTextoReposicionEnTerreno: {
      marginTop: '-18px',
    },
    tituloReposicionReposicionSinTerreno: {
      marginTop: '7px',
    },
    complementoTextoReposicionEnTerrenoSinGrafica: {
      marginTop: '-4px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '-19px',
      },
    },

    contenedorTextosSecundariosBrigadaEnTerreno: {
      marginLeft: '8px',
    },
  })
);

export default ColorBallsStyles;
