import { Typography } from '@material-ui/core';
import { fechaSinDeterminar, formatoFechaConHora } from 'utils/trackingUtils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useEffect, useState } from 'react';
import { datoNoValido, esBrigadaAsignada, esBrigadaEnTerreno } from 'utils/colorBallsUtils';
import useStyles from './ColorBallsStyles';

const ColorBallsImprevistoConBrigada = ({
  iconoInicial,
  iconoMedio,
  iconoFinal,
  data,
}: {
  iconoInicial: any;
  iconoMedio: any;
  iconoFinal: any;
  data: any;
}) => {
  /**
   * Variable que maneja el uso de estilos.
   */
  const styles = useStyles();

  /**
   * Uso del traductor
   */
  const { t } = useTranslation();

  /**
   *  Variable de estado que contiene el estado de la data si es invalida para el motivo
   */
  const [dataInvalidaMotivo, setDataInvalidaMotivo] = useState(false);

  /**
   * Obtiene el valor del switch de cortes futuros
   */
  const estadoGrafico = useSelector((state: RootState) => state.desconexion.estadoGrafico);

  /**
   * UseEffect inicializador
   * data es la respuesta del servicio que espera como prop el componente
   */
  useEffect(() => {
    setDataInvalidaMotivo(datoNoValido(data.motivo));
  }, [data]);

  /**
   * Función que permite realizar llevar al usuario a la parte final del modal
   */
  const verClientesAfectados = () => {
    // Obtener la ventana modal (ajusta el selector según estructura HTML)
    const ventanaModal = document.getElementById('modal-poligono');

    // Verificar si la ventana modal existe
    if (ventanaModal) {
      // Obtener la altura total de la ventana modal
      const alturaTotalVentanaModal = ventanaModal.scrollHeight;

      // Desplazar la ventana modal hasta el final
      ventanaModal.scrollTop = alturaTotalVentanaModal;
    }
  };

  /**
   * Función que permite dar estilos a reposicion final
   * @param estadoActualSecundario estado actual secundario
   * @param estadoGraficos estado del grafico
   * @returns estilo correspondiente
   */
  function determinarEstiloFinal(estadoActualSecundario: any, estadoGraficos: any) {
    switch (true) {
      case esBrigadaEnTerreno(estadoActualSecundario) && estadoGraficos:
        return styles.complementoTextoReposicionEnTerreno;
      case esBrigadaEnTerreno(estadoActualSecundario) && !estadoGraficos:
        return styles.complementoTextoReposicionEnTerrenoSinGrafica;
      default:
        return styles.tituloReposicionReposicionSinTerreno;
    }
  }

  return (
    <div className={styles.contenedor}>
      <div className={styles.contenedorInterno}>
        <div className={styles.contenedorIconoPrimero}>
          <img src={iconoInicial} alt="corte" />
        </div>
        <div className={styles.contenedorLineaBrigada}>
          <div className={styles.parteInferiorLineaBrigada} />
        </div>
        <div className={styles.contenedorIconoPrimero}>
          <img src={iconoMedio} alt="reparar" />
        </div>
        <div
          className={esBrigadaEnTerreno(data.estadoActualSecundario) ? styles.contenedorSegundaLineaTerreno : styles.contenedorSegundaLinea}
        >
          <div className={styles.parteInferiorSegundaLinea} />
        </div>
        <div className={styles.contenedorIconoCirculoSinIcono} />

        {esBrigadaEnTerreno(data.estadoActualSecundario) ? (
          <>
            <div className={styles.contenedorTerceraLinea}>
              <div className={styles.parteInferiorSegundaLinea} />
            </div>
            <div className={styles.contenedorIconoCirculoSinIcono} />
            <div className={styles.contenedorLinea}>
              <div className={styles.parteInferiorLinea} />
              <div className={styles.parteSuperiorLinea} />
            </div>
          </>
        ) : (
          <>
            <div className={styles.contenedorTerceraLineaSinTerreno}>
              <div className={styles.parteInferiorLinea} />
              <div className={styles.parteSuperiorLinea} />
            </div>
            <div className={styles.contenedorIconoCirculoSinIconoSinBrigadaTerreno} />
            <div className={styles.contenedorLineaUnionSinBrigadaTerreno} />
          </>
        )}
        <div className={styles.contenedorIconoFinalBrigada}>
          <img src={iconoFinal} alt="reposicion" />
        </div>
      </div>
      <div className={styles.contenedorTextos}>
        <div className={styles.contenedorPrimerosTexto}>
          <Typography className={`${styles.textoGlobal} ${styles.tituloConfirmado}`}>
            {t('desconexion:paginas.mapaCortes.modalTracking.textosStepper.titulos.corteConfirmado')}
          </Typography>
          <div className={styles.contenedorTextosMotivoCorte}>
            <Typography className={`${styles.textoGlobal} ${styles.textoPrincipalMotivoCorte}`}>
              {t('desconexion:paginas.mapaCortes.modalTracking.textosStepper.titulos.motivoCorte')}
            </Typography>
            <Typography className={`${styles.textoGlobal} ${styles.textoMotivoCorte}`}>
              {dataInvalidaMotivo ? t('desconexion:paginas.mapaCortes.modalTracking.textosStepper.textos.sinDeterminar') : data.motivo}
            </Typography>
          </div>
        </div>

        <div className={styles.contenedorTextosBrigada}>
          <Typography className={`${styles.textoGlobal} ${styles.tituloConfirmado}`}>
            {t('desconexion:paginas.mapaCortes.modalTracking.textosStepper.titulos.trabajosReposicion')}
          </Typography>
          {esBrigadaAsignada(data.estadoActualSecundario) && (
            <div className={styles.contenedorTextosSecundariosBrigadaEnTerreno}>
              <Typography className={`${styles.textoGlobal} ${styles.tituloBrigada} ${styles.complementoBrigadaAsignadaSinTerreno}`}>
                {t('desconexion:paginas.mapaCortes.modalTracking.textosStepper.titulos.asignacionBrigada')}
              </Typography>
              <div className={`${styles.contenedorTextosMotivoCorte} ${styles.complementoBrigadaAsignadaSinTerrenoTexto}`}>
                <Typography className={`${styles.textoGlobal} ${styles.textoPrincipalBrigadaTerreno} `}>
                  {t('desconexion:paginas.mapaCortes.modalTracking.textosStepper.textos.asignacionBrigada')}
                </Typography>
              </div>
              <Typography className={`${styles.textoGlobal} ${styles.tituloBrigadaSinTerreno}`}>
                {t('desconexion:paginas.mapaCortes.modalTracking.textosStepper.titulos.brigadaEnTerreno')}
              </Typography>
            </div>
          )}
          {esBrigadaEnTerreno(data.estadoActualSecundario) && (
            <div className={styles.contenedorTextosSecundariosBrigadaEnTerreno}>
              <Typography className={`${styles.textoGlobal} ${styles.tituloBrigada} ${styles.complementoBrigadaAsignada}`}>
                {t('desconexion:paginas.mapaCortes.modalTracking.textosStepper.titulos.asignacionBrigada')}
              </Typography>
              <Typography className={`${styles.textoGlobal} ${styles.tituloBrigada}`}>
                {t('desconexion:paginas.mapaCortes.modalTracking.textosStepper.titulos.brigadaEnTerreno')}
              </Typography>
              <div className={styles.contenedorTextosMotivoCorte}>
                <Typography className={`${styles.textoGlobal} ${styles.textoPrincipalBrigadaTerreno}`}>
                  {t('desconexion:paginas.mapaCortes.modalTracking.textosStepper.textos.brigadaEnTerreno')}
                </Typography>
                {estadoGrafico && (
                  <Typography onClick={verClientesAfectados} className={`${styles.textoGlobal} ${styles.textoPrincipalBrigadaTerrenoLink}`}>
                    {t('desconexion:paginas.mapaCortes.modalTracking.textosStepper.textos.brigadaEnTerrenoLink')}
                  </Typography>
                )}
              </div>
            </div>
          )}
        </div>

        <div className={`${styles.contenedorTextoReposicion} ${determinarEstiloFinal(data.estadoActualSecundario, estadoGrafico)}`}>
          <Typography className={`${styles.textoGlobal} ${styles.tituloReposicion}`}>
            {t('desconexion:paginas.mapaCortes.modalTracking.textosStepper.titulos.reposicionServicio')}
          </Typography>
          <Typography className={styles.textoHoraReposicion}>{fechaSinDeterminar(formatoFechaConHora(data.fechaFinCorte), t)}</Typography>
        </div>
      </div>
    </div>
  );
};

export default ColorBallsImprevistoConBrigada;
