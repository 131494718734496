import { createStyles, makeStyles } from '@material-ui/core';

const IconoEstadoStyles = makeStyles(() =>
  createStyles({
    icono: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '32px',
      height: '32px',
    },
  })
);

export default IconoEstadoStyles;
