import ReactDOM from 'react-dom';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import BotonCerrar from 'components/BotonCerrar/BotonCerrar';
import IconoCerrar from 'components/BotonCerrar/IconoCerrar';
import { GRUPOSAESA_LOGO } from 'assets/images';
import ModalGlobalStyles from 'utils/styles/ModalGlobalStyles';
import ModalSitioNoDisponibleStyles from './ModalSitioNoDisponibleStyles';

const ModalSitioNoDisponible = ({ cerrarModal }: { cerrarModal: () => void }) => {
  /**
   * Selecciona el elemento modal
   */
  const element = document.getElementById('modal')!;

  /**
   * Componente styles
   */
  const styles = ModalSitioNoDisponibleStyles();
  /**
   * Componente styles de modal global
   */
  const stylesGlobal = ModalGlobalStyles();

  /**
   * Componente de traduccion i18n
   */
  const { t } = useTranslation();

  return (
    element &&
    ReactDOM.createPortal(
      <div className={stylesGlobal.backdrop}>
        <section className={`${stylesGlobal.modalGlobal} ${styles.modalGlobal}`}>
          <div className={stylesGlobal.contenedorIcono}>
            <img className={styles.imagenLogoSaesa} src={GRUPOSAESA_LOGO} alt="Logo grupo saesa" />
          </div>

          <div className={styles.contenedorInterno}>
            <Typography className={`${stylesGlobal.parrafoGlobal}`}>{t('desconexion:paginas.modalSitioNoDisponible.textoUno')}</Typography>

            <Typography className={`${stylesGlobal.parrafoGlobal}`}>{t('desconexion:paginas.modalSitioNoDisponible.textoDos')}</Typography>

            <Typography className={`${stylesGlobal.parrafoGlobal} ${styles.margenSuperiorTexto}`}>
              {t('desconexion:paginas.modalSitioNoDisponible.textoTres')}
            </Typography>
          </div>

          <BotonCerrar cerrarModal={cerrarModal} />
          <IconoCerrar cerrarModal={cerrarModal} />
        </section>
      </div>,
      element
    )
  );
};

export default ModalSitioNoDisponible;
