export const ROOT = '';
export const MAPACORTES = 'mapa';
export const ESTOYSINLUZ = 'estoy-sin-luz';
export const MAPACORTESIFRAME = 'mapa-iframe';
export const PATH_ESTOY_SIN_LUZ = '/estoy-sin-luz';
export const PATH_MAPA_IFRAME = '/mapa-iframe';
export const QUEINESOMOS = 'https://web.gruposaesa.cl/web/guest/quienes-somos/nuestras-empresas';
export const INVERSIONISTA = 'https://web.gruposaesa.cl/web/guest/inversionistas/memoria-anual';
export const GRUPOSAESA = 'https://web.gruposaesa.cl/web/guest/inicio';
export const HERRAMIENTAS = 'https://web.gruposaesa.cl/web/saesa/tendido-electrico';
export const CONTACTO = 'https://web.gruposaesa.cl/web/saesa/red-de-oficinas';
export const INDICADORES = 'https://web.gruposaesa.cl/web/saesa/indicadores/conexion-y-ampliacion-de-servicios/';
export const CLIENTEHOGAR = 'https://web.gruposaesa.cl/web/saesa/cliente-hogar';
export const CLIENTEMPRESA = 'https://web.gruposaesa.cl/web/saesa/cliente-empresa';
export const SOMOSVECINOS = 'https://web.gruposaesa.cl/sustentabilidad/sustentabilidad-en-la-comunidad/somos-vecinos';
export const SEGUNDOS_REINTENTO = process.env.REACT_APP_TIEMPO_REINTENTO ?? 3000;
export const CANTIDAD_REINTENTOS = process.env.REACT_APP_CANTIDAD_REINTENTO ?? 3;
