import { Hidden } from '@material-ui/core';
import { Typography } from '@saesa/ui-kit-front';
import { TipoCorteServicio } from 'config/tipoCorteServicioEnum';
import { InfoClienteModel } from 'models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TIPOCORTE } from 'config/tiposCorte';
import { PATH_ESTOY_SIN_LUZ } from 'root-page';
import useStyles from './InfoClienteStyles';

const InfoCliente = ({ className, infoCliente }: { className?: string; infoCliente: InfoClienteModel }) => {
  /**
   * Uso de estilos
   */
  const styles = useStyles();
  /**
   * Uso del i18n para traducciones
   */
  const { t } = useTranslation();

  /**
   * Valida con un switch el estado actual
   * @returns Retorna un RactNode
   */
  const contenidoPorTipo = () => {
    switch (infoCliente.estadoServicio) {
      case TipoCorteServicio.NORMAL:
        return (
          <Typography className={styles.estadoActivo} variant="parrafo">
            {t('componentes.detalleServicio.activo')}
          </Typography>
        );
      case TipoCorteServicio.CORTADO:
        return (
          <Typography className={styles.estadoInactivo} variant="parrafo">
            {t('componentes.detalleServicio.inactivo')}
          </Typography>
        );
      case TipoCorteServicio.REPOSICION:
        return (
          <Typography className={styles.estadoTransicion} variant="parrafo">
            {t('componentes.detalleServicio.enReposicion')}
          </Typography>
        );
      case TipoCorteServicio.TRAMITE_CORTE:
        return (
          <Typography className={styles.estadoTransicion} variant="parrafo">
            {t('componentes.detalleServicio.corteTramite')}
          </Typography>
        );
      case TIPOCORTE.COD_CORTE_PROGRAMADO:
        return (
          <Typography className={styles.estadoInactivo} variant="parrafo">
            {t('desconexion:paginas.estoySinLuz.paso2.corteProgramado.estadoCorte')}
          </Typography>
        );
      case TIPOCORTE.COD_CORTE_IMPREVISTO:
        return (
          <Typography className={styles.estadoInactivo} variant="parrafo">
            {t('desconexion:paginas.estoySinLuz.paso2.corteImprevisto.estadoCorte')}
          </Typography>
        );
      default:
        return <></>;
    }
  };
  /**
   * Obtiene contenido a renderizar
   */
  const [renderContenido, setRenderContenido] = useState(contenidoPorTipo());

  useEffect(() => {
    setRenderContenido(contenidoPorTipo());
  }, [infoCliente.estadoServicio]);

  return (
    <section className={`${className} ${styles.servicio}`}>
      <section className={styles.contenedorEstado}>
        <section className={styles.numeroCliente}>
          <Hidden only={['xs', 'sm']}>
            <Typography strong="bold" className={styles.tituloCampo} variant="parrafo">
              {t('componentes.detalleServicio.numeroCliente')}
            </Typography>
          </Hidden>
          <Hidden only={['md', 'lg', 'xl']}>
            <Typography strong="bold" className={styles.tituloCampo} variant="parrafo">
              {t('componentes.detalleServicio.numeroClienteMobile')}
            </Typography>
          </Hidden>
          <Typography className={styles.tituloCampo} variant="parrafo">
            {infoCliente.numeroCliente}
          </Typography>
        </section>
        {infoCliente.codDistribuidora !== '' && (
          <section className={styles.comuna}>
            <Typography strong="bold" className={styles.tituloCampo} variant="parrafo">
              {t('componentes.detalleServicio.comuna')}
            </Typography>
            <Typography className={styles.tituloCampo} variant="parrafo">
              {infoCliente.comuna}
            </Typography>
          </section>
        )}

        {infoCliente.codDistribuidora !== '' && (
          <section className={styles.localidad}>
            <Typography strong="bold" className={styles.tituloCampo} variant="parrafo">
              {t('componentes.detalleServicio.localidad')}
            </Typography>
            <Typography className={styles.tituloCampo} variant="parrafo">
              {infoCliente.localidad}
            </Typography>
          </section>
        )}

        {infoCliente.codDistribuidora !== '' && (
          <section className={styles.estado}>
            <Typography strong="bold" className={styles.tituloCampo} variant="parrafo">
              {t('componentes.detalleServicio.estado')}
            </Typography>
            {renderContenido}
          </section>
        )}
      </section>

      <a className={styles.botonVolver} href={PATH_ESTOY_SIN_LUZ} rel="noopener noreferrer">
        <Hidden only={['xs', 'sm']}>
          <Typography strong="bold" className={styles.editar} variant="label">
            {t('componentes.detalleServicio.cambiarNumero')}
          </Typography>
        </Hidden>
        <Hidden only={['md', 'lg', 'xl']}>
          <Typography strong="bold" className={styles.editar} variant="label">
            {t('componentes.detalleServicio.cambiarNumeroMobile')}
          </Typography>
        </Hidden>
      </a>
    </section>
  );
};

InfoCliente.defaultProps = {
  className: '',
};

export default InfoCliente;
