/**
 * Eventos GTM
 */
export const EventosGtmSaesa = {
  mapaDesconexiones: {
    acceso: {
      evento: 'mapafalla-accesosalmapa',
      nombre: 'accesosalmapa',
      categoria: 'mapafalla',
    },
    busqueda: {
      busquedaServicio: {
        evento: 'mapafalla-busquedaservicio',
        nombre: 'busquedaservicio',
        categoria: 'mapafalla',
      },
      busquedaServicioError: {
        evento: 'mapafalla-errorbusquedaservicio',
        nombre: 'errorbusquedaservicio',
        categoria: 'mapafalla',
      },
      busquedaDireccion: {
        evento: 'mapafalla-busquedadireccion',
        nombre: 'busquedadireccion',
        categoria: 'mapafalla',
      },
      busquedaDireccionError: {
        evento: 'mapafalla-errorbusquedadireccion',
        nombre: 'errorbusquedadireccion',
        categoria: 'mapafalla',
      },
    },
    reportarCorte: {
      evento: 'mapafalla-reportarcorte',
      nombre: 'reportarcorte',
      categoria: 'mapafalla',
    },
    poligono: {
      llamadaTracking: {
        evento: 'mapafalla-clickpoligono',
        nombre: 'clickpoligono',
        categoria: 'mapafalla',
      },
      cargaTracking: {
        evento: 'mapafalla-clickpoligonotracking',
        nombre: 'clickpoligonotracking',
        categoria: 'mapafalla',
      },
      switchCortesFuturos: {
        evento: 'mapafalla-cortesfuturos',
        nombre: 'cortesfuturos',
        categoria: 'mapafalla',
      },
      suscripcionCortes: {
        evento: 'mapafalla-suscripcionacortes',
        nombre: 'suscripcionacortes',
        categoria: 'mapafalla',
      },
    },
  },
};
