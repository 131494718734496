import { Typography } from '@saesa/ui-kit-front';
import { ReactElement } from 'react';
import useStyles from './TextoInformativoStyles';

const TextoInformativo = ({
  className,
  titulo,
  textoBajada,
}: {
  className?: string;
  titulo: string | ReactElement;
  textoBajada?: string | ReactElement;
}) => {
  /**
   * Variable que maneja el uso de estilos.
   */
  const styles = useStyles();
  return (
    <section data-testid="map-container" className={className}>
      <Typography strong="semi-bold" variant="h2" className={styles.titulo}>
        {titulo}
      </Typography>
      <Typography strong="normal" variant="parrafo" className={styles.textoBajada}>
        {textoBajada}
      </Typography>
    </section>
  );
};

TextoInformativo.defaultProps = {
  className: '',
  textoBajada: '',
};
export default TextoInformativo;
