import { Loading } from '@saesa/ui-kit-front';
import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ESTOYSINLUZ, MAPACORTES, MAPACORTESIFRAME } from 'root-page';

const RouterConfig = ({ path }: { path: string }) => {
  const PaginaMapaCortes = lazy(() => import('pages/Desconexion/MapaCortes/MapaCortes'));
  const PaginaEstoySinLuz = lazy(() => import('pages/Desconexion/EstoySinLuz/EstoySinLuz'));

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path={`${path}${MAPACORTES}`}>
          <PaginaMapaCortes />
        </Route>
        <Route path={`${path}${MAPACORTESIFRAME}`}>
          <PaginaMapaCortes />
        </Route>
        <Route path={`${path}${ESTOYSINLUZ}`}>
          <PaginaEstoySinLuz />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default RouterConfig;
