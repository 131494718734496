export enum TipoDistribuidora {
  SAESA = 'S',
  EDELAYSEN = 'E',
  LUZ_OSORNO = 'L',
  FRONTEL = 'F',
  GLOSA_SAESA = 'Saesa',
  GLOSA_EDELAYSEN = 'Edelaysen',
  GLOSA_LUZ_OSORNO = 'Luz Osorno',
  GLOSA_FRONTEL = 'Frontel',
  GLOSA_GRUPO_SAESA = 'Grupo Saesa',
}
