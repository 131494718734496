import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { TICK_CIRCLE } from 'assets/images';
import useStyles from './AcordeonNotificacionTerStyles';

const SuscritoNotificacionTer = ({ correo }: { correo: string }) => {
  /**
   * Hook de traducción de textos
   */
  const { t } = useTranslation();

  /**
   * Hook de estilos
   */
  const styles = useStyles();

  return (
    <div className={styles.contenedorGlobalSuscrito}>
      <img src={TICK_CIRCLE} alt="cerrar" />
      <div className={styles.contenedorTextosSuscrito}>
        <Typography className={styles.textoTituloSuscrito}>
          {t('desconexion:paginas.mapaCortes.notificacionTer.clienteSuscrito.titulo')}
        </Typography>
        <div className={styles.contendorSubTextosSuscritos}>
          <Typography className={`${styles.subtituloTextoSuscrito} `}>
            {t('desconexion:paginas.mapaCortes.notificacionTer.clienteSuscrito.subTitulo')}
          </Typography>
          <Typography className={`${styles.subtituloTextoSuscrito} ${styles.textoCorreoSuscrito}`}>{correo}</Typography>
        </div>
      </div>
    </div>
  );
};

export default SuscritoNotificacionTer;
