import { Card, FlechaAtrasIcon, Typography } from '@saesa/ui-kit-front';
import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ETAPAS } from 'config/tipoEtapasEnum';
import { MAPACORTES } from 'root-page';
import { InfoClienteModel } from 'models';
import { useHistory } from 'react-router-dom';
import useStyles from './PagesItemStyle';

const PagesItem = ({
  dataTestId,
  className,
  children,
  header,
  pasoActual,
  titulo,
  infoCliente,
}: {
  dataTestId?: string;
  className?: string;
  children: ReactNode;
  header?: ReactNode | null;
  titulo: string;
  pasoActual: ETAPAS;
  infoCliente: InfoClienteModel;
}) => {
  /**
   * Componente history gestor ruteos
   */
  const history = useHistory();

  /**
   * Uso del i18n para traducciones
   */
  const { t } = useTranslation();

  /**
   * Estilos del componente
   */
  const styles = useStyles();

  /**
   * Use Effect encargado de volver al mapa desde el evento del link.
   */
  useEffect(() => {
    // Obtiene el enlace para volver al mapa en base al id del elemento
    const enlace = document.getElementById('irAlMapa');
    if (enlace) {
      enlace.addEventListener('click', e => {
        e.preventDefault();

        // Objeto de parametros para la url
        const parametros = {
          empresa: infoCliente.codDistribuidora,
          cliente: infoCliente.numeroCliente,
        };

        // Añade parametros a la url de empresa y cliente
        const url = `${enlace.getAttribute('href')}?${new URLSearchParams(parametros).toString()}`;
        history.push(url);
      });
    }
  }, [infoCliente]);

  return (
    <Card className={`${className} ${styles.pagesItem}`}>
      <section data-testid={dataTestId} className={styles.header}>
        <a
          id="irAlMapa"
          className={clsx(styles.botonVolver, {
            [styles.ocultar]: pasoActual !== ETAPAS.PASO3,
          })}
          href={MAPACORTES}
        >
          <FlechaAtrasIcon className={styles.flechaAtras} />
          <span className={styles.botonVolverTexto}>{t('desconexion:paginas.estoySinLuz.textoVolver')}</span>
        </a>
        <Typography strong="semi-bold" variant="h3" className={styles.title}>
          {titulo}
        </Typography>
        {header}
      </section>
      <section className={styles.body}>{children}</section>
    </Card>
  );
};

PagesItem.defaultProps = {
  dataTestId: '',
  className: '',
  header: null,
};

export default PagesItem;
