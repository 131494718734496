import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { alertaNaranja } from 'assets/images';
import DisclaimerTerVencidoStyles from './DisclaimerTerVencidoStyles';

const DisclaimerTerVencido = () => {
  /**
   * Variable que maneja el uso de estilos.
   */
  const styles = DisclaimerTerVencidoStyles();

  /**
   * Uso del traductor
   */
  const { t } = useTranslation();

  return (
    <div className={styles.contenedor}>
      <div className={styles.contenedorInterno}>
        <img src={alertaNaranja} alt="alerta" />
        <div className={styles.contenedorParrafos}>
          <Typography className={`${styles.parrafoGlobal} ${styles.parrafoTitulo}`}>
            {t('desconexion:paginas.mapaCortes.modalTracking.disclaimerFechaTer.titulo')}
          </Typography>

          <Typography className={`${styles.parrafoGlobal} ${styles.parrafoSubTitulo}`}>
            {t('desconexion:paginas.mapaCortes.modalTracking.disclaimerFechaTer.parrafoUno')}
          </Typography>

          <Typography className={`${styles.parrafoGlobal} ${styles.parrafoSubTitulo}`}>
            {t('desconexion:paginas.mapaCortes.modalTracking.disclaimerFechaTer.parrafoDos')}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default DisclaimerTerVencido;
