import { TipoCorteServicio } from 'config/tipoCorteServicioEnum';
import { iconoReportarCorteComercial, iconoReportarCorteCortado, iconoReportarCorteOK } from 'assets/images';
import { TIPOCORTE } from 'config/tiposCorte';
import useStyles from './IconoEstadoStyles';

const IconoEstado = ({ estadoServicio }: { estadoServicio: string }) => {
  const styles = useStyles();

  /**
   * Retorna div con ícono para cerrar vista
   *
   * @returns Retorna icono de cerrar vista
   */
  const mostrarIconoCortado: any = (icono: any) => (
    <div className={styles.icono}>
      <img src={icono} alt={TipoCorteServicio.NORMAL} />
    </div>
  );

  /**
   * Valida con un switch el estado actual
   * @returns Retorna el nodo con el icono según el estado del servicio.
   */
  const contenidoPorTipo = () => {
    switch (estadoServicio) {
      case TipoCorteServicio.NORMAL:
        return mostrarIconoCortado(iconoReportarCorteOK);
      case TipoCorteServicio.CORTADO:
        return mostrarIconoCortado(iconoReportarCorteCortado);
      case TipoCorteServicio.REPOSICION:
        return mostrarIconoCortado(iconoReportarCorteComercial);
      case TipoCorteServicio.TRAMITE_CORTE:
        return mostrarIconoCortado(iconoReportarCorteComercial);
      case TIPOCORTE.COD_CORTE_IMPREVISTO:
        return mostrarIconoCortado(iconoReportarCorteCortado);
      case TIPOCORTE.COD_CORTE_PROGRAMADO:
        return mostrarIconoCortado(iconoReportarCorteCortado);
      default:
        return <></>;
    }
  };

  /**
   * Renderiza el contenido por tipo.
   */
  const renderContenido = contenidoPorTipo();

  return <>{renderContenido}</>;
};

export default IconoEstado;
