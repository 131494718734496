import { ThemeProvider } from '@material-ui/core';
import { Loading, theme, Version } from '@saesa/ui-kit-front';
import FormValidation from 'components/FormValidation/FormValidation';
import 'i18n/config';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import useStyles from './AppStyles';
import { TelemetryProvider, VERSION } from './config';
import { AuthProvider } from './context';
import { RootRouterConfig } from './navigation';

function App() {
  const styles = useStyles();
  const conectionString = process.env.REACT_APP_INSIGHTS_CONNECTIONSTRING_KEY || '';

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <SnackbarProvider
          classes={styles}
          maxSnack={6}
          autoHideDuration={5000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Version
            showTag={process.env.REACT_APP_ENV !== 'production'}
            version={process.env.REACT_APP_VERSION}
            env={process.env.REACT_APP_ENV}
            fullVersion={JSON.stringify(VERSION, null, 2)}
          />
          <FormValidation>
            <BrowserRouter>
              <TelemetryProvider conectionString={conectionString}>
                <RootRouterConfig />
              </TelemetryProvider>
            </BrowserRouter>
          </FormValidation>
        </SnackbarProvider>
      </AuthProvider>
      <Loading />
    </ThemeProvider>
  );
}

export default App;
