import { createStyles, makeStyles, Theme } from '@material-ui/core';

const NotfoundStyle = makeStyles((theme: Theme) =>
  createStyles({
    contenedor: {
      position: 'absolute',
      width: '100%',
      minHeight: '100vh',
      zIndex: -1,
      display: 'flex',
      alignItems: 'center',
    },
    contenido: {
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      marginLeft: '30%',
      [theme.breakpoints.down('lg')]: {
        marginLeft: '15%',
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: '4%',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginTop: '10%',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginTop: '30%',
      },
    },
    texto: {
      maxWidth: '380px',
      [theme.breakpoints.down('md')]: {
        maxWidth: '300px',
      },
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    icono: {
      position: 'absolute',
      right: '0',
      [theme.breakpoints.down('md')]: {
        width: '80%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        top: '300px',
      },
    },
    title: {
      color: 'var(--primario-morado)',
    },
    subtitle: {
      marginBottom: '1rem',
      color: 'var(--primario-morado)',
    },
  })
);

export default NotfoundStyle;
