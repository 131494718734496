import { CheckTrackingIcon, RelojIcon } from '@saesa/ui-kit-front';
import { TipoColoresEnum, TipoEstadoActualTrackingCorteEnum, TipoLargoLineaEnum, TipoTrackingCorteEnum } from 'config';
import { TextoInformativo, AvisoPagoCortes, TextoInferiorReportar } from 'components';
import { EtapasModels } from 'models/EtapasModels';
import { TrackingCorteEstadosResponseModel } from 'services/models';
import { ReactElement } from 'react';
import { fechaSinDeterminar, formatoFechaConHora, formatoFecha, obtenerHora } from 'utils/trackingUtils';
import { Trans } from 'react-i18next';

/**
 * Encargado de retornar estado timeInicial
 * @param respuesta
 * @param t
 * @returns objeto timeLine inicio
 */
export const obtenerElementoTimeInicio = (respuesta: TrackingCorteEstadosResponseModel, t: any) => {
  /**
   * Valores por defecto
   */
  const etapa: EtapasModels = {
    tamanioLinea: undefined,
    colorLinea: undefined,
    colorPaso: TipoColoresEnum.VERDE_OSCURO,
    colorTitulo: TipoColoresEnum.GRIS_L43,
    colorFecha: TipoColoresEnum.GRIS_L43,
    icono: CheckTrackingIcon,
    titulo: '',
    fecha: '',
    mostrarTooltip: false,
    textoTooltip: '',
  };

  /**
   * Valores dinamicos dependiendo del caso
   */
  if (respuesta.tipo === TipoTrackingCorteEnum.IMPREVISTO) {
    etapa.fecha = fechaSinDeterminar(formatoFechaConHora(respuesta?.fechaInicioCorte), t);
    etapa.titulo = t('desconexion:paginas.tracking.corte.cortesNoProgramado.etapas.inicioCorte');
  } else if (respuesta.tipo === TipoTrackingCorteEnum.PROGRAMADO) {
    etapa.fecha = fechaSinDeterminar(formatoFechaConHora(respuesta.fechaCreacionCaso), t);
    etapa.titulo = t('desconexion:paginas.tracking.corte.cortesProgramado.etapas.notificacionCorte');
  }
  if (
    (respuesta.tipo === TipoTrackingCorteEnum.IMPREVISTO && respuesta.estadoActual === TipoEstadoActualTrackingCorteEnum.CONFIRMED) ||
    respuesta.estadoActual === TipoEstadoActualTrackingCorteEnum.ACTIVATED
  ) {
    etapa.colorTitulo = TipoColoresEnum.VERDE_OSCURO;
  }

  if (
    respuesta.estadoActual === TipoEstadoActualTrackingCorteEnum.ARRIBO_BRIGADA ||
    respuesta.estadoActual === TipoEstadoActualTrackingCorteEnum.CONFIRMED
  ) {
    etapa.tamanioLinea = TipoLargoLineaEnum.FULL;
  }

  return etapa;
};

/**
 * Encargado de retornar estado timeInicial
 * @param respuesta
 * @param t
 * @returns objeto timeLine inicio
 */
export const obtenerElementoTimeMedio = (respuesta: TrackingCorteEstadosResponseModel, t: any) => {
  /**
   * Valores por defecto
   */
  const etapa: EtapasModels = {
    tamanioLinea: TipoLargoLineaEnum.FULL,
    colorLinea: TipoColoresEnum.VERDE_OSCURO,
    colorPaso: TipoColoresEnum.VERDE_OSCURO,
    colorTitulo: TipoColoresEnum.GRIS_L43,
    colorFecha: TipoColoresEnum.GRIS_L43,
    icono: CheckTrackingIcon,
    titulo: '',
    fecha: '',
    mostrarTooltip: false,
    textoTooltip: '',
  };

  /**
   * Valores dinamicos dependiendo del caso
   */
  if (respuesta.tipo === TipoTrackingCorteEnum.IMPREVISTO) {
    etapa.fecha = fechaSinDeterminar(formatoFechaConHora(respuesta.fechaArriboBrigada), t);
    etapa.titulo = t('desconexion:paginas.tracking.corte.cortesNoProgramado.etapas.arriboBrigada');
  } else if (respuesta.tipo === TipoTrackingCorteEnum.PROGRAMADO) {
    etapa.fecha = fechaSinDeterminar(formatoFechaConHora(respuesta?.fechaInicioCorte), t);
    etapa.titulo = t('desconexion:paginas.tracking.corte.cortesProgramado.etapas.inicioCorteProgramado');
  }

  if (
    respuesta.estadoActual === TipoEstadoActualTrackingCorteEnum.ARRIBO_BRIGADA ||
    (respuesta.tipo === TipoTrackingCorteEnum.PROGRAMADO && respuesta.estadoActual === TipoEstadoActualTrackingCorteEnum.CONFIRMED)
  ) {
    etapa.colorTitulo = TipoColoresEnum.VERDE_OSCURO;
  }

  if (
    respuesta.estadoActual === TipoEstadoActualTrackingCorteEnum.ACTIVATED ||
    (respuesta.estadoActual === TipoEstadoActualTrackingCorteEnum.CONFIRMED && respuesta.tipo === TipoTrackingCorteEnum.IMPREVISTO)
  ) {
    etapa.icono = RelojIcon;
    etapa.tamanioLinea = TipoLargoLineaEnum.MEDIUM;
    etapa.colorPaso = TipoColoresEnum.GRIS_L43;
  }

  return etapa;
};

/**
 * Encargado de retornar estado timeFin
 * @param respuesta
 * @param t
 * @returns objeto timeLine inicio
 */
export const obtenerElementoTimeFin = (respuesta: TrackingCorteEstadosResponseModel, t: any) => {
  /**
   * Valores por defecto
   */
  const etapa: EtapasModels = {
    tamanioLinea: TipoLargoLineaEnum.LARGE,
    colorLinea: TipoColoresEnum.GRIS_L93,
    colorPaso: TipoColoresEnum.GRIS_L83,
    colorTitulo: TipoColoresEnum.GRIS_L43,
    colorFecha: TipoColoresEnum.GRIS_L43,
    icono: RelojIcon,
    titulo: t('desconexion:paginas.tracking.corte.cortesNoProgramado.etapas.reposicionEstimada'),
    fecha: fechaSinDeterminar(formatoFechaConHora(respuesta.fechaFinCorte), t),
    mostrarTooltip: false,
    textoTooltip: '',
  };

  /**
   * Valores dinamicos dependiendo del caso
   */
  if (
    respuesta.estadoActual === TipoEstadoActualTrackingCorteEnum.ARRIBO_BRIGADA ||
    (respuesta.estadoActual === TipoEstadoActualTrackingCorteEnum.CONFIRMED && respuesta.tipo === TipoTrackingCorteEnum.PROGRAMADO)
  ) {
    etapa.tamanioLinea = TipoLargoLineaEnum.MEDIUM;
    etapa.colorPaso = TipoColoresEnum.GRIS_L63;
    etapa.colorTitulo = TipoColoresEnum.GRIS_L43;
    etapa.colorFecha = TipoColoresEnum.GRIS_L43;
    etapa.colorLinea = TipoColoresEnum.VERDE_OSCURO;
  }
  if (respuesta.estadoActual === TipoEstadoActualTrackingCorteEnum.NORMALIZED) {
    etapa.tamanioLinea = TipoLargoLineaEnum.FULL;
    etapa.colorPaso = TipoColoresEnum.VERDE_OSCURO;
    etapa.colorTitulo = TipoColoresEnum.VERDE_OSCURO;
    etapa.colorFecha = TipoColoresEnum.GRIS_L43;
    etapa.colorLinea = TipoColoresEnum.VERDE_OSCURO;
    etapa.icono = CheckTrackingIcon;
    etapa.titulo = t('desconexion:paginas.tracking.corte.cortesNoProgramado.etapas.reposicionServicio');
  }

  return etapa;
};

/**
 * Vista de retornar vista pantalla sigo con problemas
 * @param respuesta respuesta del servicio de corte
 * @param t Una función de traducción para internacionalización.
 * @param notificacionTerActivo Boleano que si el ter esta activo
 * @param numeroCaso numero de caso del poligono
 * @param estilos estilos para la vista
 * @param programado boleano que verifica si el corte es programado
 * @returns vista de sigo con problemas
 */
export const obtenerPantallaSigoConProblemas = (
  respuesta: TrackingCorteEstadosResponseModel,
  t: any,
  notificacionTerActivo: boolean,
  numeroCaso: string,
  estilos?: any,
  programado?: any
) => {
  if (respuesta.estadoActual === TipoEstadoActualTrackingCorteEnum.SIN_FALLAS) {
    return null;
  }

  return (
    <AvisoPagoCortes
      className={estilos}
      programado={programado}
      fechaInicioCorte={formatoFecha(respuesta?.fechaInicioCorte)}
      motivoCorte={respuesta.motivo}
      fechaReposicion={fechaSinDeterminar(formatoFechaConHora(respuesta.fechaFinCorte), t)}
      horaInicioCorte={obtenerHora(respuesta.fechaInicioCorte)}
      clientesAfectados={respuesta.clientesAfectadosTotales}
      estadoCorte={respuesta.estadoActual}
      tipoCorte={respuesta.tipo}
      clientesAfectadosIniciales={respuesta.clientesAfectadosIniciales}
      porcentajeClientesRecuperados={respuesta.porcentajeClientesRecuperados}
      porcentajeClientesSinServicio={respuesta.porcentajeClientesAunSinServicio}
      notificacionTerActivo={notificacionTerActivo}
      numeroCaso={numeroCaso}
    />
  );
};

/**
 *
 * @param respuesta es la "respuesta del tracking"
 * @param t es el useTranslate
 * @returns
 */

export const obtenerTextoInformativo = (respuesta: TrackingCorteEstadosResponseModel, t: any, estilos?: any) => {
  /**
   * Componente styles
   */

  let mensaje: ReactElement<any, any> = <></>;
  if (respuesta.tipo === TipoTrackingCorteEnum.IMPREVISTO && respuesta.estadoActual === TipoEstadoActualTrackingCorteEnum.CONFIRMED) {
    mensaje = t('desconexion:paginas.tracking.corte.cortesNoProgramado.inicioCorte.mensajesEtapa.titulo');
  }
  if (respuesta.tipo === TipoTrackingCorteEnum.IMPREVISTO && respuesta.estadoActual === TipoEstadoActualTrackingCorteEnum.ARRIBO_BRIGADA) {
    mensaje = t('desconexion:paginas.tracking.corte.cortesNoProgramado.arriboBrigada.mensajesEtapa.titulo');
  }
  if (respuesta.tipo === TipoTrackingCorteEnum.IMPREVISTO && respuesta.estadoActual === TipoEstadoActualTrackingCorteEnum.NORMALIZED) {
    mensaje = t('desconexion:paginas.tracking.corte.cortesNoProgramado.reposicionServicio.mensajesEtapa.titulo');
  }
  if (respuesta.tipo === TipoTrackingCorteEnum.PROGRAMADO && respuesta.estadoActual === TipoEstadoActualTrackingCorteEnum.ACTIVATED) {
    mensaje = (
      <Trans
        i18nKey="desconexion:paginas.tracking.corte.cortesProgramado.notificacionCorte.mensajesEtapa.textoBajada"
        fecha={formatoFecha(respuesta?.fechaInicioCorte)}
        hora={obtenerHora(respuesta?.fechaInicioCorte)}
        shouldUnescape={true!}
      >
        Existe un corte de servicio programado el {{ fecha: formatoFecha(respuesta?.fechaInicioCorte) }} a las{' '}
        {{ hora: obtenerHora(respuesta?.fechaInicioCorte) }} para tu sector
      </Trans>
    );
  }
  if (respuesta.tipo === TipoTrackingCorteEnum.PROGRAMADO && respuesta.estadoActual === TipoEstadoActualTrackingCorteEnum.CONFIRMED) {
    mensaje = t('desconexion:paginas.tracking.corte.cortesProgramado.inicioCorteProgramado.mensajesEtapa.textoBajada');
  }
  if (respuesta.tipo === TipoTrackingCorteEnum.PROGRAMADO && respuesta.estadoActual === TipoEstadoActualTrackingCorteEnum.NORMALIZED) {
    mensaje = t('desconexion:paginas.tracking.corte.cortesProgramado.reposicionServicio.mensajesEtapa.textoBajada');
  }
  return <TextoInformativo className={estilos} titulo={mensaje} textoBajada="" />;
};

/**
 * Retornar vista texto inferior reportar
 * @param respuesta
 * @returns
 */
export const obtenerTextoInferiorReportar = (respuesta: TrackingCorteEstadosResponseModel, t: any, programado?: boolean) => {
  let texto = '';
  if ((respuesta.tipo === TipoTrackingCorteEnum.IMPREVISTO && respuesta.estadoActual) === TipoEstadoActualTrackingCorteEnum.NORMALIZED) {
    texto = t('desconexion:paginas.tracking.corte.cortesNoProgramado.reposicionServicio.textos.info');
  }
  if ((respuesta.tipo === TipoTrackingCorteEnum.PROGRAMADO && respuesta.estadoActual) === TipoEstadoActualTrackingCorteEnum.NORMALIZED) {
    texto = t('desconexion:paginas.tracking.corte.cortesProgramado.reposicionServicio.mensajesEtapa.textoCorte');
  }
  if (programado && TipoEstadoActualTrackingCorteEnum.ACTIVATED) {
    texto = t('desconexion:paginas.tracking.corte.cortesProgramado.reposicionServicio.mensajesEtapa.textoCorte');
  }
  if (texto === '') {
    return null;
  }
  return <TextoInferiorReportar titulo={texto} />;
};
