import { createStyles, makeStyles, Theme } from '@material-ui/core';

const ModalStyles = makeStyles((theme: Theme) =>
  createStyles({
    caja: {
      borderRadius: '15px',
      position: 'fixed',
      width: '55vh',
      height: 'auto',
      minHeight: 'auto',
      maxHeight: '62vh',
      minWidth: '55vh',
      maxWidth: '55vh',
      color: 'black',
      background: '#FFFFFF;',
      zIndex: 7,
      marginLeft: '3.125rem',
      marginTop: '3vh',
      boxShadow: ' 0px 2px 20px rgba(9, 39, 84, 0.14)',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        position: 'relative',
        marginLeft: '0vh',
        marginTop: '0vh',
        width: '100%',
        height: '100vh',
        minHeight: '100vh',
        maxHeight: '110vh',
        minWidth: '100%',
        maxWidth: 'auto',
        borderRadius: '0px',
        boxShadow: 'none',
      },
    },
    cajaIframe: {
      minHeight: '75vh !important',
      minWidth: '27% !important',
      [theme.breakpoints.down('sm')]: {
        minWidth: '100% !important',
      },
    },
    cuerpo: {
      overflowX: 'hidden',
      overflowY: 'auto',
      '&::-webkit-scrollbar': { width: '8px' },
      '&::-webkit-scrollbar-thumb': {
        background: '#D1D1D1',
        borderRadius: '40px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#EAEBF0',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '15px',
        borderBottomLeftRadius: '15px',
      },

      '&::-webkit-scrollbar-button:decrement': {
        height: '0px',
        display: 'block',
        background: 'transparent',
        borderTopRightRadius: '100%',
        borderTopLeftRadius: '100%',
        paddingTop: '100px',
      },

      '&::-webkit-scrollbar-button:end:increment': {
        display: 'block',
        height: '20px',
        background: 'transparent',
        borderTopRightRadius: '100%',
        borderTopLeftRadius: '100%',
        borderBottomRightRadius: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '1vh',
        paddingBottom: '20vh',
        '&::-webkit-scrollbar': { display: 'none' },
      },
    },
    contenedorBoton: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: '15px',
      paddingRight: '8px',
    },
    contenedorBotonImprevistos: {
      display: 'flex',
      position: 'absolute',
      justifyContent: 'flex-end',
      paddingTop: '20px',
      paddingRight: '15px',
      top: '0',
      right: '0',
    },
    contenedorBotonMobile: {
      display: 'flex',
      paddingTop: '1.5rem',
      marginLeft: '1.5rem',
      marginBottom: '1.5rem',
    },

    botonCerrar: {
      backgroundColor: 'transparent',
      border: 'none',
      position: 'absolute',
      top: '1rem',
      right: '0.3rem',
    },

    cajaTitulo: {
      marginTop: '0rem',
      marginLeft: '1.5rem',
      display: 'flex',
      alignItems: 'center',
    },
    textoTitulo: {
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '18px',
    },
    textoTituloImprevisto: {
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '21px',
      lineHeight: '28px',
      color: 'var(--secundario-azul-marino)',
      marginTop: '20px',
      marginBottom: '32px',
    },
    textoParrafo: {
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontSize: '14px',
      paddingTop: '2px',
      lineHeight: '18px',
    },
    sizeSmall: {
      padding: '1px',
    },
    linea: {
      border: '1px solid #EAEBF0',
      marginLeft: '1.5rem',
      marginRight: '1.5rem',
      marginTop: '1.3rem',
    },
    circuloRojo: {
      display: 'flex',
      width: '13px',
      height: '13px',
      backgroundColor: 'red',
      borderRadius: '50%',
      marginRight: '15px',
    },

    circuloNaranja: {
      display: 'flex',
      width: '13px',
      height: '13px',
      backgroundColor: '#E08A00',
      borderRadius: '50%',
      marginRight: '15px',
    },
    circuloAzul: {
      display: 'flex',
      width: '13px',
      height: '13px',
      backgroundColor: '#0285BF',
      borderRadius: '50%',
      marginRight: '15px',
    },
    circuloGris: {
      display: 'flex',
      width: '13px',
      height: '13px',
      backgroundColor: '#CCCFDB',
      borderRadius: '50%',
      marginRight: '15px',
    },

    children: {
      marginBottom: '30px',
      alignItems: 'center',
      marginLeft: '20px',
      marginRight: '20px',
    },
    tracking: {
      marginLeft: '3rem',
      marginRight: '1.5rem',
    },

    sinTracking: {
      alignItems: 'center',
      marginTop: '1rem',
      marginLeft: '1.1rem',
      marginRight: '1.1rem',
      backgroundColor: '#F4F4F4;',
      borderRadius: '10px',
      padding: '1.1rem',
      paddingBottom: '0.1rem',
    },

    cajaTextoNoTracking: {
      paddingBottom: '1rem',
    },

    contenedorMensaje: {
      marginTop: '1rem',
      marginLeft: '1.1rem',
      marginRight: '1.1rem',
      background: ' #F7F5FF',
      padding: '0.8rem',
      borderRadius: '0.5rem',
      display: 'flex',
      marginBottom: '2rem',

      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        height: 'auto',
        marginBottom: '2rem',
      },
    },

    mensajeIcon: {
      '--icon-primary-dark': 'var(--primario-morado)',
      width: '1rem',
      height: '1rem',
      marginTop: '0.1rem',
      marginRight: '0.7rem',
      [theme.breakpoints.down('sm')]: {
        marginRight: '1rem',
      },
    },
    contenedorTextoIcono: {
      display: 'flex',
    },
    parrafo: {
      display: 'flex',
    },
  })
);

export default ModalStyles;
