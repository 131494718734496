import DesconexionService from 'services/desconexionService';
import { TipoCaptchaDesconexiones } from 'config';
import { EventosGtmSaesa } from 'utils/GtmEvents';
import AnalyticsService from 'services/AnalyticsService';
import { HttpStatusCodeEnum } from 'config/HttpStatusCodeEnum';

/**
 * Solicita el servicio de token y notificación.
 *
 * @param estadoCaptchaV3 - Un booleano que indica si el captcha V3 está habilitado.
 * @param tokenCaptcha - El token de captcha.
 * @param email - La dirección de correo electrónico del cliente.
 * @param numeroCaso - El número de caso para el cual se solicita la notificación.
 * @param tipoDeSuscripcion - El tipo de suscripción.
 * @param t - Una función de traducción para internacionalización.
 * @param enqueueSnackbar - Una función para encolar una notificación de barra de estado.
 * @param dispatch - Una función de despacho de Redux.
 * @param actualizarEstadoClienteSuscrito - Una función para actualizar el estado de suscripción del cliente.
 * @param actualizarEstadoCaptchaV3 - Una función para actualizar el estado del captcha V3.
 * @returns Una promesa que se resuelve cuando la solicitud de notificación se completa.
 */
class NotificacionService {
  static solicitarNotificacionTer = async (
    estadoCaptchaV3: boolean,
    tokenCaptcha: string,
    email: string,
    numeroCaso: string,
    tipoDeSuscripcion: string,
    t: any,
    enqueueSnackbar: any,
    dispatch: any,
    actualizarEstadoClienteSuscrito: any,
    actualizarEstadoCaptchaV3: any
  ): Promise<void> => {
    try {
      let token: string | undefined;
      if (estadoCaptchaV3) {
        const response = await DesconexionService.solicitudDeTokenSeguridadBack(tokenCaptcha, TipoCaptchaDesconexiones.V3, email);
        token = response.token;
        const tokenString = token || '';
        localStorage.setItem('token', tokenString);
      } else {
        const response = await DesconexionService.solicitudDeTokenSeguridadBackV2(tokenCaptcha, TipoCaptchaDesconexiones.V2, email);
        token = response.token;
        const tokenString = token || '';
        localStorage.setItem('token', tokenString);
      }

      await DesconexionService.suscripcionNotificacionTer(tipoDeSuscripcion, numeroCaso, email);
      localStorage.removeItem('token');
      AnalyticsService.enviarTag(EventosGtmSaesa.mapaDesconexiones.poligono.suscripcionCortes);
      dispatch(actualizarEstadoClienteSuscrito(true));
    } catch (error: any) {
      if (estadoCaptchaV3) {
        dispatch(actualizarEstadoCaptchaV3(false));
      } else if (error?.response?.status === HttpStatusCodeEnum.CONFLICT) {
        enqueueSnackbar(t('errors:enviarNotificacionTer.errorServicioEmailSuscrito'), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(t('errors:enviarNotificacionTer.errorServicioEnviarCorreo'), {
          variant: 'error',
        });
      }
    }
  };
}

export default NotificacionService;
