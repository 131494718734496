import { Typography } from '@saesa/ui-kit-front';
import { TipoCorteServicio } from 'config/tipoCorteServicioEnum';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TIPOCORTE } from 'config/tiposCorte';
import IconoEstado from './IconoEstado/IconoEstado';
import useStyles from './MensajeServicioStyles';

const MensajeServicio = ({ className, estadoServicio }: { className?: string; estadoServicio: string }) => {
  /**
   * Estilos del componente.
   */
  const styles = useStyles();

  /**
   * Uso del i18n para traducciones
   */
  const { t } = useTranslation();

  /**
   * Genera contenido para estado de corte normal
   * @returns contenido para estado normal
   */
  const contenidoEstadoOK = () => (
    <>
      <Typography variant="parrafo" className={styles.textoTitulo}>
        {t('desconexion:paginas.estoySinLuz.paso2.contenido.titulo1')}
      </Typography>
      <Typography variant="parrafo">{t('desconexion:paginas.estoySinLuz.paso2.contenido.titulo2')}</Typography>
    </>
  );

  /**
   * Genera contenido para estado de corte comercial
   * @returns contenido para estado corte comercial
   */
  const contenidoEstadoCorteComercial = () => (
    <>
      <Typography variant="parrafo" className={styles.textoTitulo}>
        {t('desconexion:paginas.estoySinLuz.paso2.corteComercial.titulo1')}
      </Typography>
      <Typography variant="parrafo">{t('desconexion:paginas.estoySinLuz.paso2.corteComercial.titulo2')}</Typography>
    </>
  );

  /**
   * Genera contenido para estado de corte en reposición
   * @returns contenido para estado corte en reposición
   */
  const contenidoEstadoReposicion = () => (
    <>
      <Typography variant="parrafo" className={styles.textoTitulo}>
        {t('desconexion:paginas.estoySinLuz.paso2.reposicion.titulo1')}
      </Typography>
    </>
  );

  /**
   * Genera contenido para estado de corte en tramite
   * @returns contenido para estado corte en tramite
   */
  const contenidoEstadoTramiteCorte = () => (
    <>
      <Typography variant="parrafo" className={styles.textoTitulo}>
        {t('desconexion:paginas.estoySinLuz.paso2.corteTramite.titulo1')}
      </Typography>
      <Typography variant="parrafo">{t('desconexion:paginas.estoySinLuz.paso2.corteTramite.titulo2')}</Typography>
    </>
  );

  /**
   * Genera contenido para estado de corte imprevisto
   * @returns contenido para estado corte imprevisto
   */
  const contenidoEstadoCorteImprevisto = () => (
    <>
      <Typography variant="parrafo" className={styles.textoTitulo}>
        {t('desconexion:paginas.estoySinLuz.paso2.corteImprevisto.titulo1')}
      </Typography>
      <Typography variant="parrafo">{t('desconexion:paginas.estoySinLuz.paso2.corteImprevisto.titulo2')}</Typography>
    </>
  );

  /**
   * Genera contenido para estado de corte programado
   * @returns contenido para estado corte programado
   */
  const contenidoEstadoCorteProgramado = () => (
    <>
      <Typography variant="parrafo" className={styles.textoTitulo}>
        {t('desconexion:paginas.estoySinLuz.paso2.corteProgramado.titulo1')}
      </Typography>
      <Typography variant="parrafo">{t('desconexion:paginas.estoySinLuz.paso2.corteProgramado.titulo2')}</Typography>
    </>
  );

  /**
   * Genera el contneido del mensaje según el estado del servicio.
   * @returns contenido según el estado del servicio
   */
  const contenidoPorTipo = () => {
    switch (estadoServicio) {
      case TipoCorteServicio.NORMAL:
        return contenidoEstadoOK;
      case TipoCorteServicio.CORTADO:
        return contenidoEstadoCorteComercial;
      case TipoCorteServicio.REPOSICION:
        return contenidoEstadoReposicion;
      case TipoCorteServicio.TRAMITE_CORTE:
        return contenidoEstadoTramiteCorte;
      case TIPOCORTE.COD_CORTE_IMPREVISTO:
        return contenidoEstadoCorteImprevisto;
      case TIPOCORTE.COD_CORTE_PROGRAMADO:
        return contenidoEstadoCorteProgramado;
      default:
        return <></>;
    }
  };

  /**
   * Estado para manejo de contenido por tipo.
   */
  const [renderContenido, setRenderContenido] = useState(contenidoPorTipo());

  /**
   * Efecto que gatilla el cambio del mensaje por tipo
   */
  useEffect(() => {
    setRenderContenido(contenidoPorTipo());
  }, [estadoServicio]);

  return (
    <div className={`${styles.contenedor} ${className}`}>
      <IconoEstado estadoServicio={estadoServicio} />
      <section className={styles.descripcionFallas}>{renderContenido}</section>
    </div>
  );
};

MensajeServicio.defaultProps = {
  className: '',
};
export default MensajeServicio;
