import { IconButton } from '@material-ui/core';
import { CLOSE_CIRCLE } from 'assets/images';
import ModalGlobalStyles from 'utils/styles/ModalGlobalStyles';

const IconoCerrar = ({ cerrarModal }: { cerrarModal: () => void }) => {
  /**
   * Componente styles de modal global
   */
  const stylesGlobal = ModalGlobalStyles();

  return (
    <div className={stylesGlobal.contenedorCerrar}>
      <IconButton className={stylesGlobal.botonCerrar} aria-label="cerrar" onClick={cerrarModal} data-testid="cerrarModal">
        <img src={CLOSE_CIRCLE} alt="cerrar" />
      </IconButton>
    </div>
  );
};

export default IconoCerrar;
