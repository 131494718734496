import { Typography } from '@saesa/ui-kit-front';
import { TipoEstadoActualTrackingCorteEnum, TipoTrackingCorteEnum } from 'config';
import { useEffect, useState } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { useTranslation } from 'react-i18next';
import AcordeonNotificacionTer from 'components/AcordeonNotificacionTer/AcordeonNotificacionTer';
import { useDispatch } from 'react-redux';
import { actualizarEstadoGrafico } from 'store';
import useStyles from './AvisoPagoCortesStyles';

const AvisoPagoCortes = ({
  className,
  horaInicioCorte,
  fechaInicioCorte,
  fechaReposicion,
  motivoCorte,
  clientesAfectados,
  estadoCorte,
  programado,
  tipoCorte,
  clientesAfectadosIniciales,
  porcentajeClientesRecuperados,
  porcentajeClientesSinServicio,
  notificacionTerActivo,
  numeroCaso,
}: {
  className?: string;
  horaInicioCorte: string;
  fechaInicioCorte: string;
  fechaReposicion: string;
  motivoCorte?: string;
  clientesAfectados: string;
  estadoCorte: string;
  programado?: boolean;
  tipoCorte?: string;
  clientesAfectadosIniciales: any;
  porcentajeClientesRecuperados: any;
  porcentajeClientesSinServicio: any;
  notificacionTerActivo: boolean;
  numeroCaso: string;
}) => {
  /**
   * Hook de traducción de textos
   */
  const { t } = useTranslation();

  /**
   * Disparador para el selector
   */
  const dispatch = useDispatch();

  /**
   * Hook de estilos
   */
  const styles = useStyles();

  /**
   * Objeto que contiene los datos para el grafico
   */
  const objetoGrafico = [
    { name: 'clientesAfectados', value: parseInt(porcentajeClientesSinServicio, 10) },
    { name: 'clientesRecuperados', value: parseInt(porcentajeClientesRecuperados, 10) },
  ];

  /**
   * Colores para el grafico
   */
  const colores = [
    { start: '#FE0130', end: '#98011D' },
    { start: '#4EF3B4', end: '#0ECC83' },
  ];

  /**
   * Variables para modificar el grafico en caso de 100%
   */
  let paddingAngle100porciento = 5;
  let strokeOpacity100porciento = 6;
  let strokeWidth100porciento = 6;
  /**
   * Variables para indicar si se debe animar o no el grafico
   */
  let animacionActivada = true;
  /**
   * Indicador de si se debe mostrar o no gráfico
   */
  let graficoValido = false;
  /**
   * Indicador de si existe algun porcentaje al 100%
   */
  let porcentajeEn100porCiento = false;
  /**
   * Indicador de si se debe mostrar o no clientes afectados
   */
  let validacionClientesAfectados = true;

  /**
   * Funcion que valida si porcentajes y clientes afectados son validos
   */
  const validarGrafico = () => {
    porcentajeEn100porCiento = porcentajeClientesRecuperados === '100' || porcentajeClientesSinServicio === '100';

    // validación de valores válidos para gráfico, si alguno no es válido no se muestra gráfico
    if (
      porcentajeClientesRecuperados === undefined ||
      porcentajeClientesRecuperados === null ||
      porcentajeClientesRecuperados === '' ||
      porcentajeClientesSinServicio === undefined ||
      porcentajeClientesSinServicio === null ||
      porcentajeClientesSinServicio === '' ||
      clientesAfectadosIniciales === undefined ||
      clientesAfectadosIniciales === null ||
      clientesAfectadosIniciales === '0' ||
      clientesAfectadosIniciales === ''
    ) {
      graficoValido = false;
      dispatch(actualizarEstadoGrafico(graficoValido));
      return;
    }

    graficoValido = true;
    dispatch(actualizarEstadoGrafico(graficoValido));

    // validación para casos de 100%
    if (porcentajeClientesRecuperados === '100' || porcentajeClientesSinServicio === '100') {
      paddingAngle100porciento = 0;
      strokeOpacity100porciento = 0;
      animacionActivada = false;
    }

    // validación para casos de porcentajes menores a 2%
    if (parseInt(porcentajeClientesRecuperados, 10) < 2 || parseInt(porcentajeClientesSinServicio, 10) < 2) {
      strokeWidth100porciento = 0;
    }
  };

  validarGrafico();
  validacionClientesAfectados =
    (graficoValido === false && tipoCorte === TipoTrackingCorteEnum.IMPREVISTO) || tipoCorte === TipoTrackingCorteEnum.PROGRAMADO;

  const contenido = () => (
    <>
      <section className={`${styles.avisoCorteCnt} `}>
        <section className={styles.dataCorte}>
          {!programado && tipoCorte !== TipoTrackingCorteEnum.IMPREVISTO && (
            <div className={styles.item}>
              <Typography className={styles.label} strong="semi-bold" variant="parrafo">
                {t('desconexion:paginas.tracking.corte.avisoPago.textos.inicioCorte')}
              </Typography>

              {tipoCorte !== TipoTrackingCorteEnum.IMPREVISTO && fechaInicioCorte ? (
                <Typography className={styles.info} strong="normal" variant="parrafo">
                  {`${fechaInicioCorte} - ${horaInicioCorte}`}
                </Typography>
              ) : (
                <Typography className={styles.sinInfo} strong="normal" variant="parrafo">
                  {t('desconexion:paginas.tracking.corte.avisoPago.textos.sinDeterminar')}
                </Typography>
              )}
            </div>
          )}
          {!programado && tipoCorte !== TipoTrackingCorteEnum.IMPREVISTO && (
            <div className={styles.item}>
              {estadoCorte === TipoEstadoActualTrackingCorteEnum.NORMALIZED ? (
                <Typography className={styles.info} strong="semi-bold" variant="parrafo">
                  {t('desconexion:paginas.tracking.corte.avisoPago.textos.reposicionServicio')}
                </Typography>
              ) : (
                <Typography className={styles.label} strong="semi-bold" variant="parrafo">
                  {t('desconexion:paginas.tracking.corte.avisoPago.textos.reposicionEstimada')}
                </Typography>
              )}

              {tipoCorte !== TipoTrackingCorteEnum.IMPREVISTO && fechaReposicion ? (
                <Typography className={styles.info} strong="normal" variant="parrafo">
                  {fechaReposicion}
                </Typography>
              ) : (
                <Typography className={styles.sinInfo} strong="normal" variant="parrafo">
                  {t('desconexion:paginas.tracking.corte.avisoPago.textos.sinDeterminar')}
                </Typography>
              )}
            </div>
          )}

          {tipoCorte !== TipoTrackingCorteEnum.IMPREVISTO && validacionClientesAfectados && (
            <div className={styles.item}>
              <Typography className={styles.label} strong="semi-bold" variant="parrafo">
                {t('desconexion:paginas.tracking.corte.avisoPago.textos.clientesAfectados')}
              </Typography>

              {clientesAfectados ? (
                <Typography className={styles.info} strong="normal" variant="parrafo">
                  {clientesAfectados}
                </Typography>
              ) : (
                <Typography className={styles.sinInfo} strong="normal" variant="parrafo">
                  {t('desconexion:paginas.tracking.corte.avisoPago.textos.sinDeterminar')}
                </Typography>
              )}
            </div>
          )}
          {tipoCorte !== TipoTrackingCorteEnum.IMPREVISTO && (
            <div>
              <Typography className={styles.label} strong="semi-bold" variant="parrafo">
                {t('desconexion:paginas.tracking.corte.avisoPago.textos.motivoCorte')}
              </Typography>

              {motivoCorte ? (
                <Typography className={styles.info} strong="normal" variant="parrafo">
                  {motivoCorte}
                </Typography>
              ) : (
                <Typography className={styles.sinInfo} strong="normal" variant="parrafo">
                  {t('desconexion:paginas.tracking.corte.avisoPago.textos.sinDeterminar')}
                </Typography>
              )}
            </div>
          )}

          {tipoCorte === TipoTrackingCorteEnum.IMPREVISTO && notificacionTerActivo && <AcordeonNotificacionTer numeroCaso={numeroCaso} />}

          {tipoCorte === TipoTrackingCorteEnum.IMPREVISTO && graficoValido && (
            <div
              className={`${styles.contenedorGrafico}  ${
                !graficoValido && tipoCorte === TipoTrackingCorteEnum.IMPREVISTO && styles.noVistaAvisoCorteCnt
              }`}
            >
              <div>
                <Typography className={styles.tituloClientesRecuperados}>
                  {parseInt(clientesAfectadosIniciales, 10).toLocaleString('en-US').replaceAll(',', '.')}
                </Typography>
                <Typography className={styles.labelClientesRecuperados} variant="parrafo">
                  {t('desconexion:paginas.mapaCortes.GraficoClientesNormalizados.clientesAfectados')}
                </Typography>
              </div>
              {/* Circulo interno de gráfico */}

              <div className={styles.objetoSobreponer}>
                <div className={styles.ovaloGrafica}>
                  <div className={styles.contenedorInternoOvaloGrafica}>
                    <div className={styles.contenedorPorcentaje}>
                      {!porcentajeEn100porCiento && <div className={styles.circuloVerde} />}
                      <Typography className={styles.porcentajeRecuperados}>{porcentajeClientesRecuperados}%</Typography>
                    </div>
                    <Typography className={styles.textos}>
                      {t('desconexion:paginas.tracking.corte.avisoPago.textos.clientesRecuperados')}
                    </Typography>
                  </div>
                </div>
              </div>

              {/* Grafico */}
              <div className={styles.grafico}>
                <PieChart width={250} height={200}>
                  <defs>
                    {objetoGrafico.map((entry, index) => (
                      <linearGradient id={`myGradient${index}`}>
                        <stop offset="0%" stopColor={colores[index % colores.length].start} />
                        <stop offset="100%" stopColor={colores[index % colores.length].end} />
                      </linearGradient>
                    ))}
                  </defs>
                  <Pie
                    data={objetoGrafico}
                    dataKey="value"
                    cx="50%"
                    cy={100}
                    innerRadius={72}
                    outerRadius={90}
                    paddingAngle={paddingAngle100porciento}
                    labelLine={false}
                    startAngle={-270}
                    cornerRadius={10}
                    strokeWidth={strokeWidth100porciento}
                    strokeOpacity={strokeOpacity100porciento}
                    isAnimationActive={animacionActivada}
                  >
                    {objetoGrafico.map((datas, index) => (
                      <Cell key={`cell-${datas.name}`} fill={`url(#myGradient${index})`} />
                    ))}
                  </Pie>
                </PieChart>
              </div>

              {porcentajeClientesRecuperados !== '100' && (
                <div className={styles.contenedorPieDeGrafico}>
                  <div className={styles.circuloRojo} />
                  <Typography strong="semi-bold">{porcentajeClientesSinServicio}%</Typography>
                  <Typography className={styles.clientesSinServicio}>
                    {t('desconexion:paginas.mapaCortes.GraficoClientesNormalizados.clientesSinServicio')}
                  </Typography>
                </div>
              )}
            </div>
          )}
        </section>
      </section>
    </>
  );

  const [renderContenido, setRenderContenido] = useState(contenido());

  useEffect(() => {
    setRenderContenido(contenido());
  }, [
    horaInicioCorte,
    fechaInicioCorte,
    fechaReposicion,
    motivoCorte,
    clientesAfectados,
    estadoCorte,
    programado,
    clientesAfectadosIniciales,
    porcentajeClientesRecuperados,
    porcentajeClientesSinServicio,
    tipoCorte,
    notificacionTerActivo,
  ]);

  return (
    <section data-testid="map-container" className={className}>
      {renderContenido}
    </section>
  );
};

AvisoPagoCortes.defaultProps = {
  className: '',
  programado: false,
  tipoCorte: '',
  motivoCorte: '',
};

export default AvisoPagoCortes;
