import { Typography } from '@material-ui/core';
import { fechaSinDeterminar, formatoFechaConHora } from 'utils/trackingUtils';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { datoNoValido } from 'utils/colorBallsUtils';
import useStyles from './ColorBallsStyles';

const ColorBallsImprevistoSinBrigada = ({ iconoInicio, iconoFinal, data }: { iconoInicio: string; iconoFinal: string; data: any }) => {
  /**
   * Variable que maneja el uso de estilos.
   */
  const styles = useStyles();

  /**
   * Uso del traductor
   */
  const { t } = useTranslation();

  /**
   *  Variable de estado que contiene el estado de la data si es invalida para el motivo
   */
  const [dataInvalidaMotivo, setDataInvalidaMotivo] = useState(false);

  /**
   * UseEffect inicializador
   * data es la respuesta del servicio que espera como prop el componente
   */
  useEffect(() => {
    setDataInvalidaMotivo(datoNoValido(data.motivo));
  }, [data]);

  return (
    <div className={styles.contenedor}>
      <div className={styles.contenedorInterno}>
        <div className={styles.contenedorIconoPrimero}>
          <img src={iconoInicio} alt="corte" />
        </div>
        <div className={styles.contenedorLinea}>
          <div className={styles.parteInferiorLinea} />
          <div className={styles.parteSuperiorLinea} />
        </div>
        <div className={styles.contenedorIconoSegundo}>
          <img src={iconoFinal} alt="reposicion" />
        </div>
      </div>
      <div className={styles.contenedorTextos}>
        <div className={styles.contenedorPrimerosTexto}>
          <Typography className={`${styles.textoGlobal} ${styles.tituloConfirmado}`}>
            {t('desconexion:paginas.mapaCortes.modalTracking.textosStepper.titulos.corteConfirmado')}
          </Typography>
          <div className={styles.contenedorTextosMotivoCorte}>
            <Typography className={`${styles.textoGlobal} ${styles.textoPrincipalMotivoCorte}`}>
              {t('desconexion:paginas.mapaCortes.modalTracking.textosStepper.titulos.motivoCorte')}{' '}
            </Typography>
            <Typography className={`${styles.textoGlobal} ${styles.textoMotivoCorte}`}>
              {dataInvalidaMotivo ? t('desconexion:paginas.mapaCortes.modalTracking.textosStepper.textos.sinDeterminar') : data.motivo}
            </Typography>
          </div>
        </div>
        <div className={styles.contenedorSegundosTexto}>
          <Typography className={`${styles.textoGlobal} ${styles.tituloReposicion}`}>
            {t('desconexion:paginas.mapaCortes.modalTracking.textosStepper.titulos.reposicionServicio')}
          </Typography>
          <Typography className={styles.textoHoraReposicion}>{fechaSinDeterminar(formatoFechaConHora(data.fechaFinCorte), t)}</Typography>
        </div>
      </div>
    </div>
  );
};

export default ColorBallsImprevistoSinBrigada;
