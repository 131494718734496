import { createStyles, makeStyles } from '@material-ui/core';

const MensajeServicioStyles = makeStyles(() =>
  createStyles({
    contenedor: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: '.625rem',
    },
    descripcionFallas: {
      marginTop: '1rem',
      textAlign: 'center',
    },
    textoTitulo: {
      maxWidth: '430px',
      marginBottom: '4px',
      lineHeight: '24px',
      fontWeight: 600,
    },
  })
);

export default MensajeServicioStyles;
