import { TIPOZOOM } from 'config';

/**
 *
 * @param mobile define si es mobile
 * @param pec si es pec
 * @returns el valor que depende si es pec
 */
export const obtenerZoom = (mobile: any, pec: boolean): number => {
  switch (true) {
    case !mobile && pec:
      return TIPOZOOM.ESCRITORIO_PEC;
    case mobile && pec:
      return TIPOZOOM.MOBILE_PEC;
    default:
      return TIPOZOOM.DEFAULT;
  }
};
