import { createStyles, makeStyles } from '@material-ui/core';
import { theme } from '@saesa/ui-kit-front';

const DisclaimerStyles = makeStyles(() =>
  createStyles({
    card: {
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      padding: '12px 20px 12px 12px',
      gap: '14px',
      height: '64px',
      background: '#FFF0F3',
      border: '1px solid var(--rojo-error)',
      borderRadius: '10px',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginBottom: '36px',
      paddingRight: 'inherit',
      [theme.breakpoints.down('sm')]: { height: 'auto' },
    },

    frame: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: 0,
      gap: '0.260416vw',
      [theme.breakpoints.down('sm')]: { gap: '4px' },
    },
    icono: {
      display: 'flex',
      maxWidth: '1.822vw',
      [theme.breakpoints.down('sm')]: { maxWidth: '28px' },
    },
    text: {
      fontFamily: 'Neutrif Studio',
      fontWeight: 600,
      fontSize: '0.9114583333333334vw',
      lineHeight: ' 1.171875vw',
      color: 'var(--gris-l3)',
      [theme.breakpoints.down('sm')]: { fontSize: '14px', lineHeight: '18px' },
    },
    subtext: {
      fontFamily: 'Neutrif Studio',
      fontWeight: 400,
      fontSize: '0.78125vw',
      lineHeight: '1.0416vw',
      color: 'var(--gris-l43)',
      [theme.breakpoints.down('sm')]: { fontSize: '12px', lineHeight: '16px' },
    },
  })
);

export default DisclaimerStyles;
