import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enCommon from './en/common.json';
import enDesconexion from './en/desconexion.json';
import enErrors from './en/errors.json';
import esCommon from './es/common.json';
import esDesconexion from './es/desconexion.json';
import esErrors from './es/errors.json';

export const resources = {
  en: {
    translation: enCommon,
    desconexion: enDesconexion,
    errors: enErrors,
  },
  es: {
    translation: esCommon,
    desconexion: esDesconexion,
    errors: esErrors,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: 'es',
  resources,
  ns: ['translation', 'desconexion', 'migracion'],
});
