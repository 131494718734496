import { TipoEstadoActualSecundarioTrackingCorteEnum, TipoEstadoActualTrackingCorteEnum, TipoTrackingCorteEnum } from 'config';

/**
 * Ejemplo de respuesta de servicio de tracking.
 */
export const newResponseTracking = {
  clientesAfectadosTotales: '22',
  clientesNormalizados: '0',
  estadoActual: TipoEstadoActualTrackingCorteEnum.NORMALIZED,
  fechaArriboBrigada: '2022-07-01T11:28:25',
  fechaCreacionCaso: '',
  fechaFinCorte: '2022-07-01T11:30:00',
  fechaInicioCorte: '2022-07-01T08:43:52',
  motivo: 'Caída de alumbrado por choque de carros',
  tipo: TipoTrackingCorteEnum.IMPREVISTO,
  clientesRecuperados: '',
  clientesAfectadosIniciales: '',
  porcentajeClientesRecuperados: '',
  porcentajeClientesAunSinServicio: '',
  estadoActualSecundario: TipoEstadoActualSecundarioTrackingCorteEnum.CONFIRMADO,
  fechaActualizacion: '2022-07-01T11:30:00',
};
