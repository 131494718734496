import { addMinutes, isBefore } from 'date-fns';
import { LOCALE_EN_US, zonaHoraria } from './constants';

/**
 * Comprueba si una hora proporcionada está vencida en comparación con la hora actual en Chile.
 * @param hora La hora proporcionada en formato ISO 8601.
 * @returns true si la hora proporcionada está vencida (anterior a la hora actual en Chile), false de lo contrario.
 */
export const esFechaVencida = (hora: string): boolean => {
  // Verificar si la hora es una cadena vacía o contiene solo espacios
  if (hora !== undefined && hora !== null)
    if (!hora.trim()) {
      return true;
    }

  // Obtener la hora actual en Chile
  const horaActualChile = new Date().toLocaleString(LOCALE_EN_US, { timeZone: zonaHoraria });

  // Convertir la hora proporcionada a un objeto Date
  const horaProporcionada = new Date(hora);

  // Comparar las horas y devolver true si la hora proporcionada es menor que la hora actual en Chile
  return isBefore(addMinutes(horaProporcionada, 1), new Date(horaActualChile));
};
