import { createSlice } from '@reduxjs/toolkit';
import { DesconexionState } from './desconexionState.interface';

const initialState: DesconexionState = {
  distribuidora: {
    urlLogo: '',
  },
  servicio: {
    id: '',
  },
  usuario: {
    numeroTelefono: '',
    apellido: '',
    email: '',
    id: '',
    nombre: '',
    rut: '',
  },
  menuAbierto: false,
  modalMobileAbierto: false,
  modalMobileAbiertoCompleto: false,
  mensajeFlotanteCerrado: false,
  modoPEC: true,
  numeroSolicitud: '',
  cortesFuturos: false,
  switchCortesFuturos: false,
  estadoGrafico: false,
  modalPaginaNoDisponible: {
    tiempoCookie: 0,
    activacionModal: false,
  },
  flagCargado: false,
  flagCargadoEstoySinLuz: false,
  flagCargadoNotificacionTer: false,
  tokenCaptchaV3: '',
  captchaV3Estado: false,
  captchaV2Estado: false,
  acordeonNotificacionTerAbierto: false,
  pasoActual: 1,
  clienteSuscritoTer: false,
};

export const desconexionSlice = createSlice({
  name: 'desconexion',
  initialState,
  reducers: {
    actualizarLogo: (state, action) => {
      state.distribuidora.urlLogo = action.payload?.urlLogo || state.distribuidora.urlLogo;
    },
    actualizarEstadoMenuAbierto: (state, action) => {
      state.menuAbierto = action.payload.menuAbierto;
    },
    actualizarEstadoModalMobileAbierto: (state, action) => {
      state.modalMobileAbierto = action.payload.modalMobileAbierto;
    },
    actualizarEstadoModalMobileAbiertoCompleto: (state, action) => {
      state.modalMobileAbiertoCompleto = action.payload.modalMobileAbiertoCompleto;
    },
    actualizarEstadoMensajeFlotanteCerrado: (state, action) => {
      state.mensajeFlotanteCerrado = action.payload.mensajeFlotanteCerrado;
    },
    actualizarFeatureFlagModoPEC: (state, action) => {
      state.modoPEC = action.payload;
    },
    actualizarNumeroSolicitud: (state, action) => {
      state.numeroSolicitud = action.payload;
    },
    actualizarFeatureFlagCortesFuturos: (state, action) => {
      state.cortesFuturos = action.payload;
    },
    actualizarSwitchCortesFuturos: (state, action) => {
      state.switchCortesFuturos = action.payload;
    },
    actualizarEstadoGrafico: (state, action) => {
      state.estadoGrafico = action.payload;
    },
    actualizarEstadoModalPaginaNoDisponible: (state, action) => {
      state.modalPaginaNoDisponible.tiempoCookie = action.payload.tiempoCookie;
      state.modalPaginaNoDisponible.activacionModal = action.payload.activacionModal;
    },
    actualizarEstadoFlag: (state, action) => {
      state.flagCargado = action.payload;
    },
    actualizarEstadoFlagEstoySinLuz: (state, action) => {
      state.flagCargadoEstoySinLuz = action.payload;
    },
    actualizarEstadoFlagNotificacionTer: (state, action) => {
      state.flagCargadoNotificacionTer = action.payload;
    },
    actualizarTokenCaptchaV3: (state, action) => {
      state.tokenCaptchaV3 = action.payload;
    },
    actualizarEstadoCaptchaV3: (state, action) => {
      state.captchaV3Estado = action.payload;
    },
    actualizarEstadoCaptchaV2: (state, action) => {
      state.captchaV2Estado = action.payload;
    },
    actualizarAcordeonNotificacionTerAbierto: (state, action) => {
      state.acordeonNotificacionTerAbierto = action.payload;
    },
    actualizarPasoActual: (state, action) => {
      state.pasoActual = action.payload;
    },
    actualizarEstadoClienteSuscrito: (state, action) => {
      state.clienteSuscritoTer = action.payload;
    },
  },
});

export const {
  actualizarLogo,
  actualizarEstadoMenuAbierto,
  actualizarEstadoModalMobileAbierto,
  actualizarEstadoModalMobileAbiertoCompleto,
  actualizarEstadoMensajeFlotanteCerrado,
  actualizarFeatureFlagModoPEC,
  actualizarNumeroSolicitud,
  actualizarFeatureFlagCortesFuturos,
  actualizarSwitchCortesFuturos,
  actualizarEstadoGrafico,
  actualizarEstadoModalPaginaNoDisponible,
  actualizarEstadoFlag,
  actualizarEstadoFlagEstoySinLuz,
  actualizarEstadoFlagNotificacionTer,
  actualizarTokenCaptchaV3,
  actualizarEstadoCaptchaV3,
  actualizarEstadoCaptchaV2,
  actualizarAcordeonNotificacionTerAbierto,
  actualizarPasoActual,
  actualizarEstadoClienteSuscrito,
} = desconexionSlice.actions;

export default desconexionSlice.reducer;
