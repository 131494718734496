import { TIPOCORTE } from 'config/tiposCorte';
import IconButton from '@material-ui/core/IconButton';
import cerrarModalLogo from 'assets/images/cerrar-modal.svg';
import { Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { actualizarEstadoModalMobileAbierto, actualizarEstadoModalMobileAbiertoCompleto } from 'store';
import { fechaSinDeterminar, formatoFechaConHora } from 'utils/trackingUtils';
import useStyles from './VentanaMobileStyles';
import useStylesModal from '../Modal/ModalStyles';

const VentanaMobile = ({
  titulo,
  setActivadorMobile,
  setVentanaMobileState,
  modalTracking,
  setMostrarModal,
  setServicioCargado,
  setMostrarBuscador,
  primeraData,
  segundaData,
}: {
  titulo: string;
  setActivadorMobile: any;
  setVentanaMobileState: any;
  modalTracking: any;
  setMostrarModal: any;
  setServicioCargado: any;
  setMostrarBuscador: any;
  primeraData: any;
  segundaData: any;
}) => {
  /**
   * Pagina de estilos de componente.
   */
  const styles = useStyles();
  /**
   * Pagina de estilos de componente modal.
   */
  const stylesModal = useStylesModal();

  /**
   * Uso del i18n para traducciones.
   */
  const { t } = useTranslation();

  /**
   * Disparador para el selector
   */
  const dispatch = useDispatch();

  const cerrarModal = () => {
    setActivadorMobile(false);
    setVentanaMobileState(false);
    setMostrarBuscador(true);
    setMostrarModal(false);
    dispatch(actualizarEstadoModalMobileAbierto({ modalMobileAbierto: false }));
    dispatch(actualizarEstadoModalMobileAbiertoCompleto({ modalMobileAbiertoCompleto: false }));
  };

  const verMasDetalles = () => {
    setActivadorMobile(true);
    setVentanaMobileState(false);
    setMostrarModal(true);
    setServicioCargado(true);
    dispatch(actualizarEstadoModalMobileAbiertoCompleto({ modalMobileAbiertoCompleto: true }));
  };

  /**
   * Retorna el estilo del circulo segun sea el tipo de corte.
   *
   * @param tipo de corte
   * @returns Estilo de circulo asociado a corte
   */
  const tipoCorte = (tipo: string) => {
    let corte = '';
    switch (tipo) {
      case TIPOCORTE.CORTE_IMPREVISTO:
        corte = stylesModal.circuloRojo;
        break;

      case TIPOCORTE.CORTE_PROGRAMADO:
        corte = stylesModal.circuloNaranja;
        break;
      case TIPOCORTE.PROXIMO_CORTE_PROGRAMADO:
        corte = stylesModal.circuloAzul;
        break;

      default:
        break;
    }
    return corte;
  };

  return (
    <>
      <div className={styles.cajaVentana}>
        <div className={styles.contenedorTituloBoton}>
          <div className={styles.contenedorBotonMobile}>
            <IconButton
              title="botonCerrarMobile"
              aria-label="cerrar ventana"
              onClick={cerrarModal}
              size="small"
              className={styles.sizeSmallMobile}
            >
              <img src={cerrarModalLogo} alt="Cerrar" />
            </IconButton>
          </div>
          {titulo && (
            <>
              <div className={styles.cajaTituloMobile}>
                <div className={tipoCorte(titulo)} />
                {modalTracking &&
                  (TIPOCORTE.PROXIMO_CORTE_PROGRAMADO === titulo ? (
                    <Typography className={styles.textoTituloMobile}>{titulo}</Typography>
                  ) : (
                    <Typography className={styles.textoTituloMobile}>
                      {t('desconexion:paginas.mapaCortes.modalTracking.corteEnCurso', {
                        total: titulo,
                      })}
                    </Typography>
                  ))}

                {modalTracking === false && <Typography className={styles.textoTituloMobile}>{titulo}</Typography>}
              </div>

              <div className={styles.lineaMobile} />
            </>
          )}
        </div>

        {modalTracking && (
          <div className={styles.cajaContenedoraInformacion}>
            <div className={styles.cajaMobileInformacion}>
              {/* Titulo pantalla modal con tracking */}
              {titulo === TIPOCORTE.CORTE_PROGRAMADO && (
                <Typography className={styles.textoTituloInformacion}>
                  {t('desconexion:paginas.mapaCortes.modalMobile.tituloItemUnoProgramado')}
                </Typography>
              )}

              {titulo === TIPOCORTE.PROXIMO_CORTE_PROGRAMADO && (
                <Typography className={styles.textoTituloInformacion}>
                  {t('desconexion:paginas.mapaCortes.modalMobile.tituloItemUnoProgramadoFuturo')}
                </Typography>
              )}
              {titulo !== TIPOCORTE.CORTE_IMPREVISTO && (
                <Typography className={styles.textoParrafoInformacion}>
                  {primeraData === t('desconexion:paginas.tracking.corte.avisoPago.textos.sinDeterminar')
                    ? fechaSinDeterminar(primeraData, t)
                    : formatoFechaConHora(primeraData)}
                </Typography>
              )}
            </div>
            <div className={styles.cajaMobileInformacion}>
              <Typography className={styles.textoTituloInformacion}>
                {t('desconexion:paginas.mapaCortes.modalMobile.tituloItemDos')}
              </Typography>

              <Typography className={styles.textoParrafoInformacion}>
                {segundaData === t('desconexion:paginas.tracking.corte.avisoPago.textos.sinDeterminar')
                  ? fechaSinDeterminar(segundaData, t)
                  : formatoFechaConHora(segundaData)}
              </Typography>
            </div>
          </div>
        )}

        {modalTracking === false && (
          <div className={styles.cajaContenedoraInformacion}>
            <div className={styles.cajaMobileInformacion}>
              <Typography className={styles.textoTituloInformacion}>{primeraData?.titulo}:</Typography>

              <Typography className={styles.textoParrafoInformacion}>{primeraData?.texto}</Typography>
            </div>
            <div className={styles.cajaMobileInformacion}>
              <Typography className={styles.textoTituloInformacion}>{segundaData?.titulo}:</Typography>

              <Typography className={styles.textoParrafoInformacion}>{segundaData?.texto}</Typography>
            </div>
          </div>
        )}

        <div className={styles.cajaBoton}>
          <Button
            onClick={() => {
              verMasDetalles();
            }}
            className={styles.boton}
            data-testid="dataTestId"
          >
            <Typography className={styles.textoBotonVerMasDetalles}>
              {t('desconexion:paginas.mapaCortes.modalMobile.botonDetalles')}
            </Typography>
          </Button>
        </div>
      </div>
    </>
  );
};

export default VentanaMobile;
