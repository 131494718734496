import { TipoEstadoActualTrackingCorteEnum, TipoTrackingCorteEnum } from 'config';
import { TipoCorteServicio } from 'config/tipoCorteServicioEnum';
import { TipoDistribuidora } from 'config/tipoDistribuidoraEnum';
import { TIPOCORTE } from 'config/tiposCorte';
import { InfoClienteModel } from 'models';
import { EstadoServicioResponseModel } from 'services/models';

/**
 * Metodo que se encarga de validar si un dato existe.
 * @param dato valor del dato a validar.
 * @returns true si es valido, false si es invalido.
 */
export const esDatoValido = (dato: any) => dato && dato !== undefined && dato !== null;

/**
 * Setea textos y url por distribuidora del cliente.
 */
export const setearDistribuidora = (infoCliente: InfoClienteModel) => {
  switch (infoCliente.codDistribuidora) {
    case TipoDistribuidora.SAESA:
      return { glosa: TipoDistribuidora.GLOSA_SAESA, url: process.env.REACT_APP_URL_HOME_SAESA! };
    case TipoDistribuidora.EDELAYSEN:
      return { glosa: TipoDistribuidora.GLOSA_EDELAYSEN, url: process.env.REACT_APP_URL_HOME_EDELAYSEN! };
    case TipoDistribuidora.FRONTEL:
      return { glosa: TipoDistribuidora.GLOSA_FRONTEL, url: process.env.REACT_APP_URL_HOME_FRONTEL! };
    case TipoDistribuidora.LUZ_OSORNO:
      return { glosa: TipoDistribuidora.GLOSA_LUZ_OSORNO, url: process.env.REACT_APP_URL_HOME_LUZOSORNO! };
    default:
      return { glosa: TipoDistribuidora.GLOSA_GRUPO_SAESA, url: process.env.REACT_APP_URL_GRUPO_SAESA! };
  }
};

/**
 * Valida el estado de corte y actualiza el estado de servicio del cliente según las condiciones.
 *
 * @param {EstadoServicioResponseModel} responseCodigo - Objeto que contiene información sobre el estado del servicio.
 * @param {InfoClienteModel} infoClienteModel - Objeto que representa información sobre el cliente y su servicio.
 * @returns {InfoClienteModel} - Objeto infoClienteModel actualizado con el estado de servicio modificado si corresponde.
 */
export const validarCorte = (responseCodigo: EstadoServicioResponseModel, infoClienteModel: InfoClienteModel) => {
  const corte = responseCodigo.cortes;
  const infoCliente = infoClienteModel;
  if (
    responseCodigo.codigoEstadoSuministro === TipoCorteServicio.NORMAL &&
    corte?.estadoActual === TipoEstadoActualTrackingCorteEnum.CONFIRMED
  ) {
    const tipoCorteMapping: { [key in TipoTrackingCorteEnum]: TIPOCORTE } = {
      [TipoTrackingCorteEnum.DEFAULT]: TIPOCORTE.DEFAULT,
      [TipoTrackingCorteEnum.PROGRAMADO]: TIPOCORTE.COD_CORTE_PROGRAMADO,
      [TipoTrackingCorteEnum.IMPREVISTO]: TIPOCORTE.COD_CORTE_IMPREVISTO,
    };
    if (corte?.tipo && corte.tipo in tipoCorteMapping) {
      infoCliente.estadoServicio = tipoCorteMapping[corte.tipo as TipoTrackingCorteEnum];
    }
  }
  return infoCliente;
};
